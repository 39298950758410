import React, { FC, useState } from "react";
import NcModal from "../../components/NcModal/NcModal";
import Tag from "../../components/Tag/Tag";
import { TaxonomyType } from "../../data/types";
import Button from "../../components/Button/Button";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { KindCategory } from "templates/Ncmaz/utils/function";

export interface ModalTagsProps {
  tags: TaxonomyType[];
  kindCategory?: KindCategory;
}

const ModalTags: FC<ModalTagsProps> = ({ tags, kindCategory = 'news' }) => {
  const [openModal, setOpenModal] = useState(false);

  const renderModalContent = () => {
    return (
      <div className="flex flex-wrap dark:text-neutral-200">
        {tags.map((tag) => (
          <Tag onClick={() => setOpenModal(false)} key={tag.id} tag={tag} className="mr-2 mb-2" kindCategory={kindCategory} />
        ))}
      </div>
    );
  };

  return (
    <div className="nc-ModalTags">
      <NcModal
        contentExtraClass="max-w-screen-md"
        isOpenProp={openModal}
        onCloseModal={() => setOpenModal(false)}
        renderTrigger={(openModal) => (
          <Button
            pattern="third"
            fontSize="text-sm font-medium"
            onClick={() => setOpenModal(true)}
          >
            <div>
              <span className="hidden sm:inline">Other</span> Tags
            </div>
            <ChevronDownIcon
              className="w-4 h-4 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Button>
        )}
        modalTitle="Discover other tags"
        renderContent={renderModalContent}
      />
    </div>
  );
};

export default ModalTags;
