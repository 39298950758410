import { TaxonomyType } from "../../data/types";
import Link from "../../components/Link";
import React, { FC } from "react";
import { KindCategory, handleHrefTag } from "templates/Ncmaz/utils/function";

export interface TagProps {
  className?: string;
  tag: TaxonomyType;
  hideCount?: boolean;
  taxonomy?: 'category' | 'tag' | undefined,
  onClick?: any;
  kindCategory?: KindCategory;
}

const Tag: FC<TagProps> = ({ className = "", tag, hideCount = false, taxonomy = 'tag', onClick, kindCategory = 'news' }) => {

  return (
    <Link
      className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900 ${className}`}
      href={handleHrefTag(tag.href, kindCategory)}
      onClick={() => {
        onClick && onClick()
      }}
    >
      {tag.name}
      {!hideCount && (
        <span className="text-xs font-normal"> ({tag.count})</span>
      )}
    </Link>
  );
};

export default Tag;
