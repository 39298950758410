/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import ReactPlayer from "react-player";
import NcPlayIcon from "../../../../components/NcPlayIcon/NcPlayIcon";
import CategoryBadgeList from "../../../../components/CategoryBadgeList/CategoryBadgeList";
import PostMeta2 from "../../../../components/PostMeta2/PostMeta2";
import isSafariBrowser from "../../../../utils/isSafariBrowser";
import Image from "../../../../components/Image";
import SingleTitle from "../../../../app/(singles)/SingleTitle";
import SingleMetaAction2 from "../../../../app/(singles)/SingleMetaAction2";
import { getData } from "templates/Ncmaz/utils/services";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";
import Layout from "../../layout";
import { formatDataNewDetail, formatNewDataArr } from 'templates/Ncmaz/utils/function'

const PageSingleVideo = () => {
  const [isPlay, setIsPlay] = useState(false);
  const [newsDetail, setNewsDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isRendered, setIsRendered] = useState(false);
  const [relatedNewsData, setRelatedNewsData] = useState()
  const [moreFromAuthorData, setMoreFromAuthorData] = useState()
  const [widgetData, setWidgetData] = useState([])
  const { slug } = useParams()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    getData(`/news-detail/${slug}`)
      .then((res) => {
        setNewsDetail(formatDataNewDetail(res.data.entity))
        setRelatedNewsData(formatNewDataArr(res.data.relatedNews))
        setMoreFromAuthorData(formatNewDataArr(res.data.moreFromAuthor))
        setWidgetData(res.data.widget)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err, 'error')
        setIsLoading(false)
        navigate('/page404')
      })
  }, [slug, navigate])

  useEffect(() => {
    setIsRendered(true);
    document.title = 'Lotusa CMS | News Detail'
  }, []);

  const renderMainVideo = () => {
    return (
      <div className="">
        {isSafariBrowser() && !isPlay && (
          <div
            className="absolute inset-0 z-10 cursor-pointer "
            onClick={() => setIsPlay(true)}
          >
            <Image
              src={newsDetail?.featuredImage}
              className="object-cover"
              sizes="(max-width: 1024px) 100vw, 50vw"
              alt="single"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <NcPlayIcon />
            </div>
          </div>
        )}
        {isRendered && (
          <ReactPlayer
            url={newsDetail?.videoUrl}
            className="absolute inset-0"
            playing={isSafariBrowser() ? isPlay : true}
            width="100%"
            height="100%"
            controls
            light={
              isSafariBrowser()
                ? false
                : newsDetail?.featuredImage
            }
            playIcon={<NcPlayIcon />}
          />
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={`nc-SingleHeader `}>
        <div className="space-y-5 dark text-neutral-100">
          <CategoryBadgeList
            itemClass="!px-3"
            categories={newsDetail?.categories}
          />
          <SingleTitle
            mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100"
            title={newsDetail.title}
          />
          {newsDetail.desc && (
              <span className="hidden xl:block text-lg text-neutral-500 dark:text-neutral-400">
                  {newsDetail.desc}
              </span>
          )}

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col space-y-5">
            <PostMeta2
              size="large"
              className="leading-none flex-shrink-0"
              hiddenCategories
              avatarRounded="rounded-full shadow-inner"
              meta={newsDetail}
            />
            <SingleMetaAction2 
              commentCount={newsDetail?.commentCount} 
              likeCount={newsDetail?.like?.count} 
              slug={newsDetail?.href}
            />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <LoadingScreen />

  return (
    <Layout 
      newsDetail={newsDetail}
      relatedNews={relatedNewsData}
      moreFromAuthor={moreFromAuthorData}
      widget={widgetData}
    >
      <header className="container relative py-14 lg:py-20 flex flex-col lg:flex-row lg:items-center">
        <div className="nc-PageSingleVideo__headerWrap absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-r-[40px]"></div>
        <div className="pb-10 lg:pb-0 lg:pr-10 relative">{renderHeader()}</div>
        <div className="relative lg:w-8/12 flex-shrink-0">
          <div className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0">
            {renderMainVideo()}
          </div>
        </div>
      </header>
    </Layout>
  );
};

export default PageSingleVideo;
