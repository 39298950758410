import React from 'react'
import imageContent from './styles/images/Fartmart.png'
function FartmartEcommerce() {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <img
                style={{ objectFit: 'contain', width: '100%' }}
                src={imageContent}
                alt=""
            />
        </div>
    )
}

export default FartmartEcommerce
