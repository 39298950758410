import * as ReactIcons from 'react-icons/fa'
import React from 'react'

const IconComponent = ({ icon, size = 20 }) => {
    const DynamicIconComponent = ReactIcons[icon]

    if (undefined === DynamicIconComponent) return null

    return <DynamicIconComponent size={size} />
}
export default IconComponent
