import React, { useState } from "react";
import ButtonClose from "../../components/ButtonClose/ButtonClose";
import { AlertType } from "templates/Ncmaz/data/types";

export interface AlertProps {
  children: React.ReactNode;
  containerClassName?: string;
  type?: AlertType;
  open: boolean;
  onClose?: (open: boolean) => void
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
  open = true,
  onClose
}) => {
  // let classes = containerClassName;
  // switch (type) {
  //   case "default":
  //     classes += " text-black bg-neutral-900";
  //     break;
  //   case "info":
  //     classes += " bg-status-infoBg text-status-info";
  //     break;
  //   case "success":
  //     classes += " bg-status-successBg text-status-success";
  //     break;
  //   case "error":
  //     classes += " bg-status-errorBg text-status-error";
  //     break;
  //   case "warning":
  //     classes += " bg-status-warningBg text-status-warning";
  //     break;
  //   default:
  //     break;
  // }
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-stone-600";
      break;
    case "info":
      classes += " text-white bg-sky-600";
      break;
    case "success":
      classes += " text-white bg-lime-600";
      break;
    case "error":
      classes += " text-white bg-red-600";
      break;
    case "warning":
      classes += " text-white bg-orange-600	";
      break;
    default:
      break;
  }
  const [openAlert, setOpenAlert] = useState(open);
  return (
    <>
      {openAlert &&
        <div
          className={`ttnc-alert flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg ${classes} fixed bottom-10 left-10 z-50`}
        >
          <i className="pe-7s-info text-2xl mr-2"></i>
          {children}
          <ButtonClose className="" onClick={() => {
            setOpenAlert(false)
            onClose && onClose(false)
          }} />
        </div>}
    </>
  );
};
