import { FC } from "react";
import { SectionMagazine1Props } from "../Sections/SectionMagazine1";
import Card19 from "../../components/Card19/Card19";
import Card18 from "../../components/Card18/Card18";

export interface HottestProps extends SectionMagazine1Props { }

const Component: FC<HottestProps> = ({
    posts,
    className = "",
}) => {

    return (
        <div className={`nc-SectionMagazine10 ${className}`}>
            {posts?.length === 0 && <span>Nothing we found!</span>}
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-5 gap-5">
                    {posts
                        .filter((_, i) => i < 3 && i >= 1)
                        .map((item: any, index: number) => (
                        <Card18
                            showCategories={false}
                            className="sm:row-span-3 col-span-1"
                            key={index}
                            post={item}
                        />
                    ))}

                    {posts[3] && (
                        <Card19
                            ratio="aspect-w-4 aspect-h-3 sm:aspect-h-1 sm:aspect-w-16 "
                            className="sm:col-span-2 sm:row-span-2"
                            titleClass="text-xl sm:text-2xl xl:text-2xl"
                            post={posts[3]}
                            showCategories={false}
                        />
                    )}
                </div>

                {posts[0] && <Card19 className="" post={posts[0]} />}
            </div>
        </div>
    );
};

export default Component;
