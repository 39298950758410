import React, { FC } from 'react'
import twFocusClass from '../../utils/twFocusClass'

export interface PaginationProps {
    className?: string
    totalPage?: number
    setPageSetting?: any
    pageSetting?: PageSetting
}
interface PageSetting {
    page: number
    pageSize: number
    totalPage: number
}
const Pagination: FC<PaginationProps> = ({
    className = '',
    setPageSetting,
    pageSetting = {
        page: 0,
        pageSize: 5,
        totalPage: 1,
    },
}) => {
    // const pageNumber = Array.from(Array(pageSetting?.totalPage).keys())
    const pageNumber = Array.from(
        { length: pageSetting?.totalPage },
        (_, i) => i + 1
    )
    const handleClickPage = (page: number) => {
        setPageSetting((preState: PageSetting) => {
            return {
                ...preState,
                page: page,
            }
        })
    }
    const renderItem = (pag: number) => {
        if (pag === pageSetting?.page + 1) {
            // RETURN ACTIVE PAGINATION
            return (
                <span
                    key={pag}
                    className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white hover:cursor-pointer ${twFocusClass()}`}
                    onClick={() => handleClickPage(pag - 1)}
                >
                    {pag}
                </span>
            )
        }
        // RETURN UNACTIVE PAGINATION
        return (
            <span
                key={pag}
                className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800  hover:cursor-pointer dark:border-neutral-700 ${twFocusClass()}`}
                onClick={() => handleClickPage(pag - 1)}
            >
                {pag}
            </span>
        )
    }

    return (
        <nav
            className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
        >
            {pageNumber.map(renderItem)}
        </nav>
    )
}

export default Pagination
