import React from "react";
import PostCardCommentBtn from "../PostCardCommentBtn/PostCardCommentBtn";
import PostCardLikeAction from "../PostCardLikeAction/PostCardLikeAction";


const MyPostCardLikeAndComment = ({
  className = "",
  itemClass = "px-3 h-8 text-xs",
  hiddenCommentOnMobile = true,
  useOnSinglePage = false,
  commentCount = 0,
  likeCount = 0,
  liked = false,
  slug = '',
  onClick
}) => {
  return (
    <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}
    >
      <PostCardLikeAction
        className={itemClass}
        likeCount={likeCount}
        liked={liked}
        onClick={() => onClick && onClick()}
      />
      <PostCardCommentBtn
        commentCount={commentCount}
        className={`${
          hiddenCommentOnMobile ? "hidden sm:flex" : "flex"
        }  ${itemClass}`}
        isATagOnSingle={useOnSinglePage}
        slug={slug}
      />
    </div>
  );
};

export default MyPostCardLikeAndComment;
