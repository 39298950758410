import React from 'react'
import MyRoutes from './routers'
import './styles/index.scss'
import './styles/main.css'

function Ncmaz() {
    return (
        <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
                <MyRoutes />
        </div>
    )
}

export default Ncmaz
