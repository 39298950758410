import React, { FC, useState } from "react";

export interface RadioProps {
    value: any[];
    className?: string,
    selected: any;
    onChange?: (value: string) => void;
}

const RadioGroup: FC<RadioProps> = ({
    value,
    className = '',
    selected,
    onChange
}) => {

    const [itemSelected, setItemSelected] = useState<string>(selected);
    

    return (
        <div className={` `}>
            {value?.length > 0 &&
                value?.map((item: any, idx: number) => (
                    <label key={idx} className={`mx-3 ${className}`}>
                        <input
                            className="mr-1"
                            type="radio"
                            name="gender"
                            value={item}
                            checked={itemSelected === item ? true : false}
                            title={item.toUpperCase()}
                            onChange={(ev) => {
                                setItemSelected(ev.target.value)
                                onChange && onChange(ev.target.value)
                            }}
                        />
                        <span>{item}</span>
                    </label>
                ))}
        </div>
    )
}

export default RadioGroup;