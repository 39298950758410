import { SectionMagazine1Props } from "../Sections/SectionMagazine1";
import {FC} from 'react'
import Component from "./page";

export interface LatestArticles1Props extends SectionMagazine1Props { }

const LatestArticles1: FC<LatestArticles1Props> = ({
  posts,
  heading = 'Latest Articles',
  className = 'pt-16 lg:pt-24',
  desc
}) => {
  return (
    <div className="container relative">
      <Component
        posts={posts}
        heading={heading}
        className={className}
        desc={desc}
      />
    </div>
  )
}

export default LatestArticles1;