import React, { useEffect } from 'react'
import facebookSvg from '../../../images/Facebook.svg'
import twitterSvg from '../../../images/Twitter.svg'
import googleSvg from '../../../images/Google.svg'
import Input from '../../../components/Input/Input'
import ButtonPrimary from '../../../components/Button/ButtonPrimary'
import NcLink from '../../../components/NcLink/NcLink'
import Heading2 from '../../../components/Heading/Heading2'
import Image from '../../../components/Image'
import Layout from '../layout'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'

const loginSocials = [
    {
        name: 'Continue with Facebook',
        href: '#',
        icon: facebookSvg,
    },
    {
        name: 'Continue with Twitter',
        href: '#',
        icon: twitterSvg,
    },
    {
        name: 'Continue with Google',
        href: '#',
        icon: googleSvg,
    },
]
export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
    function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    }
)


const PageSignUp = () => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState<string | boolean>(false)
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    useEffect(() => {
        document.title = 'Lotusa CMS | Register'
    }, [])
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .required('Required!')
                .matches(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    'Please type valid email!'
                ),
            password: Yup.string()
                .min(8, 'Minimum 8 characters')
                .required('Required!'),
        }),
        onSubmit: (values) => {
            const url = `${process.env.REACT_APP_API_URL}/auth/local/register`
            const body = { ...values, username: values.email }
            axios
                .post(url, body)
                .then((res) => {
                    formik.values.password = ''
                    formik.values.email = ''
                    setOpen('success')
                })
                .catch((err) => {
                    setOpen('fail')
                    console.log(err)
                })
        },
    })
    const displayToast = (key: string, color: AlertColor, message: string) => {
        return (
            <Snackbar
                open={open === key}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={color}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        )
    }
    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
                <Heading2>Sign up</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Welcome to our blog magazine Community
                </span>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                <div className="grid gap-3">
                    {loginSocials.map((item, index) => (
                        <a
                            key={index}
                            href={item.href}
                            className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                        >
                            <Image
                                className="flex-shrink-0"
                                src={item.icon}
                                alt={item.name}
                            />
                            <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                {item.name}
                            </h3>
                        </a>
                    ))}
                </div>
                {/* OR */}
                <div className="relative text-center">
                    <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                        OR
                    </span>
                    <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                </div>
                {/* FORM */}
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-cols-1 gap-6"
                >
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                        </span>
                        <Input
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.email && formik.touched.email && (
                            <p className="text-xs text-red-500 mt-1">
                                {formik.errors.email}
                            </p>
                        )}
                    </label>
                    <label className="block">
                        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Password
                        </span>
                        <Input
                            type="password"
                            className="mt-1"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.password && formik.touched.password && (
                            <p className="text-xs text-red-500 mt-1">
                                {formik.errors.password}
                            </p>
                        )}
                    </label>

                    <ButtonPrimary type="submit">Continue</ButtonPrimary>
                </form>
                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    Already have an account? {` `}
                    <NcLink href="/login">Sign in</NcLink>
                </span>
            </div>
            {displayToast(
                'success',
                'success',
                'Thank you for register! You have to confirm your email address. Please check your email!'
            )}
            {displayToast(
                'fail',
                'error',
                'Email are already taken! Please try again!'
            )}
        </Layout>
    )
}

export default PageSignUp
