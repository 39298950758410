import React, { FC } from "react";
import Badge from "../../components/Badge/Badge";
import { TaxonomyType } from "templates/Ncmaz/data/types";
import { DEMO_CATEGORIES } from "templates/Ncmaz/data/taxonomies";

export interface CategoryBadgeListProps {
    className?: string;
    itemClass?: string;
    categories?: TaxonomyType[];
}

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
    className = "flex flex-wrap space-x-2",
    itemClass,
    categories = [DEMO_CATEGORIES[2]]
}) => {

    return (
        <div
            className={`nc-CategoryBadgeList ${className}`}
            data-nc-id="CategoryBadgeList"
        >
            {categories?.map((item, index) => (
                <Badge
                    className={itemClass}
                    key={index}
                    name={item.name}
                    href={item.href}
                    color={item.color as any}
                    layout={item.layout}
                />
            ))}
        </div>
    );
};

export default CategoryBadgeList;
