// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Poppins/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Poppins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Poppins/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: Poppins;\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n/*  */\n:root {\n  --font-display: Poppins;\n  --font-body: Poppins;\n}", "",{"version":3,"sources":["webpack://./src/templates/Ncmaz/styles/__theme_font.scss"],"names":[],"mappings":"AACA;EACE,oBAAA;EACA,gBAAA;EACA,4CAAA;AAAF;AAEA;EACE,oBAAA;EACA,gBAAA;EACA,4CAAA;AAAF;AAEA;EACE,oBAAA;EACA,gBAAA;EACA,4CAAA;AAAF;AAEA;EACE,oBAAA;EACA,gBAAA;EACA,4CAAA;AAAF;AAEA;EACE,oBAAA;EACA,gBAAA;EACA,4CAAA;AAAF;AAGA,KAAA;AACA;EACE,uBAAA;EACA,oBAAA;AADF","sourcesContent":["// POPPINS\n@font-face {\n  font-family: Poppins;\n  font-weight: 300;\n  src: url(\"../fonts/Poppins/Poppins-Light.ttf\");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 400;\n  src: url(\"../fonts/Poppins/Poppins-Regular.ttf\");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 500;\n  src: url(\"../fonts/Poppins/Poppins-Medium.ttf\");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 600;\n  src: url(\"../fonts/Poppins/Poppins-SemiBold.ttf\");\n}\n@font-face {\n  font-family: Poppins;\n  font-weight: 700;\n  src: url(\"../fonts/Poppins/Poppins-Bold.ttf\");\n}\n\n/*  */\n:root {\n  --font-display: Poppins;\n  --font-body: Poppins;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
