/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NcImage from "../../../../components/NcImage/NcImage";
import ListingImageGallery from "../../../../components/listing-image-gallery/ListingImageGallery";
import SingleHeader from "../../../../app/(singles)/SingleHeader";
import { imgHigtQualitys } from "../../../../contains/fakeData";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import usePathname from "../../../../hooks/usePathname";
import Layout from "../../layout";
import { getData } from "templates/Ncmaz/utils/services";
import { formatDataNewDetail, formatNewDataArr } from "templates/Ncmaz/utils/function";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";

const IMAGES_GALLERY: string[] = imgHigtQualitys;

const PageSingleGallery = () => {
  const [newsDetail, setNewsDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [relatedNewsData, setRelatedNewsData] = useState([])
  const [moreFromAuthorData, setMoreFromAuthorData] = useState([])
  const [widgetData, setWidgetData] = useState([])
  const { slug } = useParams()
  const navigate = useNavigate()
  const thisPathname = usePathname();
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  //

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");

    navigate(`${thisPathname}/?${params.toString()}`);
  };
  const handleOpenModalImageGallery = () => {
    navigate(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    getData(`/news-detail/${slug}`)
      .then((res) => {
        setNewsDetail(formatDataNewDetail(res.data.entity))
        setRelatedNewsData(formatNewDataArr(res.data.relatedNews))
        setMoreFromAuthorData(formatNewDataArr(res.data.moreFromAuthor))
        setWidgetData(res.data.widget)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err, 'error')
        setIsLoading(false)
        navigate('/page404')
      })
  }, [slug, navigate])

  useEffect(() => {
    document.title = 'Lotusa CMS | News Detail'
  }, [])

  if (isLoading) return <LoadingScreen />
  return (
    <Layout
      newsDetail={newsDetail}
      relatedNews={relatedNewsData}
      moreFromAuthor={moreFromAuthorData}
      widget={widgetData}
    >
      <div className={`pt-8 lg:pt-16`}>
        {/* SINGLE HEADER */}
        <header className="container rounded-xl">
          <SingleHeader hiddenDesc newsDetail={newsDetail}/>
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-2 my-10">
            <div
              className="col-span-2 row-span-2 relative rounded-xl overflow-hidden cursor-pointer z-0"
              onClick={handleOpenModalImageGallery}
            >
              <NcImage
                alt="single"
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-xl"
                fill
                src={newsDetail?.galleryImgs[0] || IMAGES_GALLERY[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {(newsDetail?.galleryImgs || IMAGES_GALLERY).filter((_: any, i: number) => i >= 1 && i < 5).map(
              (item: any, index: number) => (
                <div
                  key={index}
                  className={`relative rounded-xl overflow-hidden z-0 ${
                    index >= 2 ? "hidden sm:block" : ""
                  }`}
                >
                  <NcImage
                    alt="single"
                    fill
                    containerClassName="aspect-w-6 aspect-h-5"
                    className="object-cover w-full h-full rounded-xl"
                    src={item || ""}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              )
            )}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-full bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>

        <ListingImageGallery
          isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
          onClose={handleCloseModalImageGallery}
          images={(newsDetail?.galleryImgs || IMAGES_GALLERY).map((item: any, index: number) => {
            return {
              id: index,
              url: item,
            };
          })}
        />
      </div>
    </Layout>
  );
};

export default PageSingleGallery;
