import React, { FC, useState } from 'react'
import ButtonCircle from '../../components/Button/ButtonCircle'
import rightImg from '../../images/SVG-subcribe2.png'
import Badge from '../../components/Badge/Badge'
import Input from '../../components/Input/Input'
import Image from '../../components/Image'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { validateEmail } from 'templates/Ncmaz/utils/function'

export interface SectionSubscribe2Props {
    className?: string
}
interface NewLetter {
    status: boolean
    email: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = '' }) => {
    const [open, setOpen] = React.useState(false)
    const [newLetter, setNewLetter] = useState<NewLetter>({
        status: true,
        email: '',
    })
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    
    const setStatus = (status: boolean) => {
        setNewLetter((preState) => ({
            ...preState,
            status: status,
        }))
    }
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const statusEmail = validateEmail(newLetter.email)
        console.log(statusEmail)
        if (statusEmail) {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/search-page-new-letters`,
                    {
                        data: {
                            email: newLetter.email,
                        },
                    }
                )
                .then((res) => {
                    setNewLetter({
                        status: true,
                        email: '',
                    })
                    setOpen(true)
                })
                .catch((err) => console.log(err))
            setStatus(true)
        } else {
            setStatus(false)
        }
    }
    return (
        <div
            className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
        >
            <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
                <h2 className="font-semibold text-4xl">
                    Join our newsletter 🎉
                </h2>
                <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
                    Read and share new perspectives on just about any topic.
                    Everyone’s welcome.
                </span>
                <ul className="space-y-5 mt-10">
                    <li className="flex items-center space-x-4">
                        <Badge name="01" />
                        <span className="font-medium text-neutral-700 dark:text-neutral-300">
                            Get more discount
                        </span>
                    </li>
                    <li className="flex items-center space-x-4">
                        <Badge color="red" name="02" />
                        <span className="font-medium text-neutral-700 dark:text-neutral-300">
                            Get premium magazines
                        </span>
                    </li>
                </ul>
                <form
                    className="mt-10 relative max-w-sm"
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <Input
                        required
                        aria-required
                        placeholder="Enter your email"
                        type="email"
                        value={newLetter.email}
                        onChange={(e) =>
                            setNewLetter((preState) => ({
                                ...preState,
                                email: e.target.value,
                            }))
                        }
                        onFocus={(e) => {
                            setStatus(true)
                        }}
                    />
                    {newLetter.status === false && (
                        <span className="mt-3 ml-2 text-sm text-red-500">
                            Please enter a valid email address
                        </span>
                    )}
                    <ButtonCircle
                        type="submit"
                        className="absolute transform -translate-y-1/2 right-1 dark:bg-neutral-300 dark:text-black"
                        style={{ top: '22.5px' }}
                    >
                        <ArrowRightIcon className="w-5 h-5 " />
                    </ButtonCircle>
                </form>
            </div>
            <div className="flex-grow">
                <Image
                    alt="subsc"
                    sizes="(max-width: 768px) 100vw, 50vw"
                    src={rightImg}
                />
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    Thank you for subscription!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SectionSubscribe2
