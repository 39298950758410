import Component from "./page"


const LatestArticles2 = ({ posts = [], heading = '', desc = '', className = 'py-16 lg:py-24', postCardName = 'card4', gridClass = 'sm:grid-cols-2' }) => {


    return (
        <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100 mt-20">
            <div className="relative container">
                <Component
                    heading={heading}
                    className={className}
                    posts={posts}
                    postCardName={postCardName}
                    gridClass={gridClass}
                    desc={desc}
                />
            </div>
        </div>
    )
}

export default LatestArticles2