import React, { FC } from "react";
import NcImage from "../../components/NcImage/NcImage";
import { TaxonomyType } from "../../data/types";
import Link from "../../components/Link";
import { KindCategory, handleHrefCategory } from "templates/Ncmaz/utils/function";

export interface CardCategory1Props {
  className?: string;
  taxonomy: TaxonomyType;
  size?: "large" | "normal";
  onClick?: any;
  kindCategory?: KindCategory;
}

const CardCategory1: FC<CardCategory1Props> = ({
  className = "",
  size = "normal",
  taxonomy,
  onClick,
  kindCategory = 'news'
}) => {
  const { count, name, href = "/", thumbnail, layout } = taxonomy;

  return (
    <Link
      href={handleHrefCategory(layout, href, kindCategory)}
      className={`nc-CardCategory1 flex items-center ${className}`}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <NcImage
        alt=""
        containerClassName={`relative flex-shrink-0 ${
          size === "large" ? "w-20 h-20" : "w-12 h-12"
        } rounded-lg mr-4 overflow-hidden`}
        src={thumbnail || "https://th.bing.com/th/id/OIP.OladhaPCbBV9RR5t11ONHwHaF7?w=223&h=180&c=7&r=0&o=5&pid=1.7"}
        fill
        className="object-cover"
        sizes="80px"
      />
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
        >
          {name}
        </h2>
        <span
          className={`${
            size === "large" ? "text-sm" : "text-xs"
          } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
          {count} Articles
        </span>
      </div>
    </Link>
  );
};

export default CardCategory1;
