// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingScreen_loading-indicator-container__soULu {\n    min-height: 85vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    padding: 36px;\n  }\n  \n  .LoadingScreen_loading-indicator-circle__u6g-U {\n    width: 75px;\n    height: 75px;\n    border-radius: 50%;\n    border: 5px solid #ccc;\n    border-top-color: #66c2a5;\n    animation: LoadingScreen_spin__zg5sK 0.8s ease-in-out infinite;\n  }\n  \n  @keyframes LoadingScreen_spin__zg5sK {\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  ", "",{"version":3,"sources":["webpack://./src/templates/Ncmaz/components/LoadingScreen/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,8DAAyC;EAC3C;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loading-indicator-container {\n    min-height: 85vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    padding: 36px;\n  }\n  \n  .loading-indicator-circle {\n    width: 75px;\n    height: 75px;\n    border-radius: 50%;\n    border: 5px solid #ccc;\n    border-top-color: #66c2a5;\n    animation: spin 0.8s ease-in-out infinite;\n  }\n  \n  @keyframes spin {\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-indicator-container": "LoadingScreen_loading-indicator-container__soULu",
	"loading-indicator-circle": "LoadingScreen_loading-indicator-circle__u6g-U",
	"spin": "LoadingScreen_spin__zg5sK"
};
export default ___CSS_LOADER_EXPORT___;
