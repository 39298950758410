/* eslint-disable react-hooks/exhaustive-deps */
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import Input from "../../../../components/Input/Input";
import Label from "../../../../components/Label/Label";
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import InputImage from "templates/Ncmaz/components/InputImage/InputImage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "templates/Ncmaz/app/store";
import Avatar from '../../../../components/Avatar/Avatar'
import NcModal from "../../../../components/NcModal/NcModal";
import { changePassword, getProfile, updateProfile, updateUserAvatar } from "../../../../utils/services";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../features/user/userSlice";
import { formatDataAuthor } from "../../../../utils/function";
import Textarea from "templates/Ncmaz/components/Textarea/Textarea";
import { Alert } from "templates/Ncmaz/components/Alert/Alert";
import { AlertType } from "templates/Ncmaz/data/types";

const DashboardEditProfile = () => {

    const navigate = useNavigate()
    interface IFormFields {
        displayName: string;
        firstName: string;
        lastName: string;
        email: string;
        jobName: string;
        desc: string;
        currentPassword: string;
        newPassword: string;
        id: any
    }
    interface IMessage {
        type: AlertType;
        message: string;
        open: boolean
    }
    const [form, setForm] = useState<IFormFields>({
        displayName: '',
        firstName: '',
        lastName: '',
        email: '',
        jobName: '',
        desc: '',
        currentPassword: '',
        newPassword: '',
        id: ''
    });
    const userInfo = useSelector((state: RootState) => state.user.user)
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState<IMessage>({
        type: 'info',
        message: '',
        open: false
    });
    const [isChanged, setIsChanged] = useState(false);
    const [disableChangePassButton, setDisableChangePassButton] = useState(true);

    const token = localStorage.getItem('expireToken');
    const dispatch = useDispatch()

    useEffect(() => {
        if (userInfo) {
            const { displayName,
                firstName,
                lastName,
                email,
                jobName,
                desc, id } = userInfo

            setForm({
                ...form, displayName,
                firstName,
                lastName,
                email,
                jobName,
                desc, id
            })
        }
    }, [userInfo])
    const renderContent = () => {
        return (
            <div className="block">
                <InputImage
                    onClick={(file) => {
                        if (userInfo.avatar.url !== file.url) {
                            updateUserAvatar(userInfo.id, token, file.id)
                                .then(res => {
                                    getProfile(token)
                                        .then(res => {
                                            dispatch(setUser(formatDataAuthor(res.data)))
                                        })
                                        .catch(err => {
                                            console.log(err.message)
                                        })
                                })
                                .catch(err => {
                                    console.log(err.message)
                                })
                        }
                    }}
                />
            </div>
        )
    }

    const handleChangeInput = (value: string, stateName: string) => {
        setForm({ ...form, [stateName]: value })
    }

    const handleChangePassword = () => {
        setIsSending(true)
        if (form.currentPassword.trim() !== '' && form.newPassword.trim() !== '') {
            changePassword(token, form.currentPassword, form.newPassword, form.newPassword)
                .then(res => {
                    setIsSending(false)
                    setError({
                        ...error,
                        open: true,
                        type: 'success',
                        message: 'Change password successfully!'
                    })
                    setForm({ ...form, currentPassword: '', newPassword: '' })
                })
                .catch(err => {
                    setIsSending(false)
                    setError({
                        ...error,
                        open: true,
                        type: 'error',
                        message: err?.response?.data?.error?.message
                    })
                })
        }

    }

    const handleSubmitForm = () => {
        setIsSending(true)
        if (form.displayName.trim() === '') {
            setError({
                ...error,
                type: 'error',
                message: 'Username Is Required!',
                open: true
            })
            setIsSending(false)
        } else {
            updateProfile(form.id, token, {
                username: form.displayName,
                firstName: form.firstName,
                lastName: form.lastName,
                jobName: form.jobName,
                desc: form.desc,
            })
                .then(res => {
                    setIsSending(false)
                    setForm({ ...form, currentPassword: '', newPassword: '' })
                    setError({
                        ...error,
                        open: true,
                        type: 'success',
                        message: 'Submit Successfully!'
                    })
                    getProfile(token)
                        .then(res => {
                            dispatch(setUser(formatDataAuthor(res.data)))
                        })
                })
                .catch(err => {
                    setIsSending(false)
                    setError({
                        ...error,
                        open: true,
                        type: 'error',
                        message: err.message
                    })

                })
        }
    }

    useEffect(() => {
        if (!token) {
            navigate('/login')
        }

        document.title = 'Lotusa CMS | Edit Profile'
    }, [])

    useEffect(() => {
        const { desc, displayName, email, firstName, jobName, lastName } = userInfo
        if (form) {
            if (form.desc === desc && form.displayName === displayName && form.email === email
                && form.firstName === firstName && form.jobName === jobName && form.lastName === lastName ) {
                setIsChanged(false)
            } else {
                setIsChanged(true)
            }
        }

        if (form.currentPassword.trim() === '' || form.newPassword.trim() === '') {
            setDisableChangePassButton(true)
        } else {
            setDisableChangePassButton(false)
        }
    }, [form])


    return (
        <Layout>
            <LayoutDashboard>
                <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6">
                        <div className="md:row-span-3 text-center">
                            <div className="group/item md:h-full content-center w-full relative h-60">
                                <Avatar
                                    imgUrl={userInfo?.avatar}
                                    sizeClass="lg:w-9/12 h-full md:w-11/12 w-1/2"
                                />
                                <div className="group/edit hidden w-full m-auto absolute bottom-0 left-0 group-hover/item:block">
                                    <NcModal
                                        renderContent={renderContent}
                                        modalTitle={'Upload Your Avatar'}
                                        contentExtraClass="w-6/12 h-8/12"
                                        triggerText={'Change Avatar'}
                                        buttonStyle={"sm:text-xs sm:px-2.5 sm:py-2 sm:mt-1.5 "}
                                        pattern={'primary'}
                                    />
                                </div>
                            </div>
                        </div>
                        <label className="block">
                            <Label>First name</Label>
                            <Input type="text" className="mt-1" value={form?.firstName} onChange={(ev) => handleChangeInput(ev.target.value, 'firstName')} />
                        </label>
                        <label className="block">
                            <Label>Last name</Label>
                            <Input type="text" className="mt-1" value={form?.lastName} onChange={(ev) => handleChangeInput(ev.target.value, 'lastName')} />
                        </label>
                        <label className="block">
                            <Label>User name *</Label>
                            <Input required type="text" className="mt-1" value={form?.displayName} onChange={(ev) => handleChangeInput(ev.target.value, 'displayName')} />
                        </label>

                        <label className="block">
                            <Label>Job name</Label>
                            <Input type="text" className="mt-1" value={form?.jobName} onChange={(ev) => handleChangeInput(ev.target.value, 'jobName')} />
                        </label>
                        <label className="block">
                            <Label>Email address</Label>
                            <Input
                                type="email"
                                value={form?.email}
                                className="mt-1 cursor-not-allowed"
                                disabled
                            />
                        </label>
                        <label className="block md:col-span-2">
                            <Label>Description</Label>
                            <Textarea
                                value={form?.desc || ''}
                                className="mt-1"
                                placeholder="Intro About Yourself"
                                onChange={(ev) => handleChangeInput(ev.target.value, 'desc')}
                            />
                        </label>
                        <label className="block">
                            <Label>Current password</Label>
                            <Input placeholder="***" type="password" className="mt-1" value={form?.currentPassword}
                                onChange={(ev) => handleChangeInput(ev.target.value, 'currentPassword')}
                                required={form?.newPassword?.trim() !== '' ? true : false}
                            />
                        </label>
                        <label className="block">
                            <Label>New password</Label>
                            <Input type="password" className="mt-1" value={form?.newPassword}
                                onChange={(ev) => handleChangeInput(ev.target.value, 'newPassword')}
                                required={form?.currentPassword?.trim() !== '' ? true : false}
                            />
                        </label>
                        <label className="block md:col-span-2">
                            <hr />
                        </label>
                        {error.open && <Alert open={true} children={error.message} type={error.type} onClose={(boolean) => setError({ ...error, open: false, type: 'info', message: '' })} />}
                        <ButtonPrimary  className={`${disableChangePassButton ? 'hidden' : 'block md:col-span-2'} ${!disableChangePassButton ? '' : 'cursor-not-allowed'}`} loading={isSending} disabled={disableChangePassButton} onClick={!isSending ? handleChangePassword : () => { }}>
                            Change Password
                        </ButtonPrimary>

                        <ButtonPrimary className={` ${disableChangePassButton ? 'block md:col-span-2' : 'hidden'} ${isChanged ? '' : 'cursor-not-allowed'}`} loading={isSending} disabled={!isChanged} onClick={isChanged ? handleSubmitForm : () => { }}>
                            Update profile
                        </ButtonPrimary>
                    </div>
                </div>
            </LayoutDashboard>
        </Layout>
    );
};
export default DashboardEditProfile;