import React, { FC } from "react";
import WidgetAuthors from "../../components/WidgetAuthors/WidgetAuthors";
import WidgetCategories from "../../components/WidgetCategories/WidgetCategories";
import WidgetPosts from "../../components/WidgetPosts/WidgetPosts";
import WidgetTags from "../../components/WidgetTags/WidgetTags";
import Component from "templates/Ncmaz/components";

export interface SidebarProps {
  className?: string;
  widget?: any[];
}

export const Sidebar: FC<SidebarProps> = ({
  className = "space-y-6 ",
  widget = []
}) => {

  if (widget.length > 0) return (
    <div className={`nc-SingleSidebar ${className}`}>
      {widget.map((item, index) => {
        return <div key={index}>{(Component(item))}</div>
      })}
    </div>
  )

  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <WidgetTags data={{}} />
      <WidgetCategories data={{}} />
      <WidgetAuthors data={{}} />
      <WidgetPosts data={{}} />
    </div>
  );
};
