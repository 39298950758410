/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { lazy, Suspense } from 'react'
import LoadingScreen from './LoadingScreen'

const Hottest = lazy(() => import('../components/Hottest/index'))
const AllTypePost = lazy(() => import('../components/AllTypePost/index'))
const LatestArticle1 = lazy(() => import('../components/LatestArticles1/index'))
const LatestArticles2 = lazy(() => import('../components/LatestArticles2/index'))
const WidgetTags = lazy(() => import('./WidgetTags/WidgetTags'))
const WidgetCategories = lazy(() => import('./WidgetCategories/WidgetCategories'))
const WidgetAuthors = lazy(() => import('./WidgetAuthors/WidgetAuthors'))
const WidgetPosts = lazy(() => import('./WidgetPosts/WidgetPosts'))  
// const AudioList = lazy(() => import('../components/AudioList'))

const Components = {
    "Hottest": Hottest,
    "AllTypePost": AllTypePost,
    "LatestArticlesWithoutWidget": LatestArticle1,
    "LatestArticlesWithWidget": LatestArticles2,
    "Tag": WidgetTags,
    "TrendingTopic": WidgetCategories,
    "Author": WidgetAuthors,
    "PopularPost": WidgetPosts,
    // "AudioList": AudioList   // posts là mảng 2 chiều, không format dữ liệu đưa vào được
}

const Component = (data, isContent = false) => {

    if (typeof Components[data.__component] !== 'undefined' || typeof Components[data.componentName] !== 'undefined') {
        return !isContent
            ? React.createElement(Components[data.__component], {
                key: data.__component + data.id,
                data,
            })
            : React.createElement(Components[data.componentName], {
                key: data.componentName + data.id,
                posts: data.items,
                heading: data.componentTitle,
                desc: data.shortDesc
            })
    }
}

const WrapperComponent = (data, isContent = false) => {
    return <Suspense fallback={<LoadingScreen />}>{Component(data, isContent)}</Suspense>
}
export default WrapperComponent
