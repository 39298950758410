import { formatDataCategories } from "templates/Ncmaz/utils/function";
import CardCategory1 from "../../components/CardCategory1/CardCategory1";
import WidgetHeading1 from "../../components/WidgetHeading1/WidgetHeading1";
import React, { FC } from "react";

export interface IDataCategory {
  title?: string,
  icon?: string,
  items?: any[],
}


export interface WidgetCategoriesProps {
  className?: string;
  data: IDataCategory;
  isDynamic?: boolean;
}

const WidgetCategories: FC<WidgetCategoriesProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  data,
    isDynamic = true
}) => {
  const { title = 'Trending topic', items } = data

  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
    >
      <WidgetHeading1
        title={`✨ ${title}`}
        viewAll={{ label: "View all", href: "/#" }}
      />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {(items && isDynamic) && formatDataCategories(items).map((category: any) => (
            <CardCategory1
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={category.id}
              taxonomy={category}
              size="normal"
            />
          ))}
            {(items && !isDynamic) && items.map((category: any) => (
                <CardCategory1
                    className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                    key={category.id}
                    taxonomy={category}
                    size="normal"
                />))}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategories;
