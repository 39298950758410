import React, { useState, useEffect } from "react";
import SectionAds from "../../../components/Sections/SectionAds";
import { getData } from "templates/Ncmaz/utils/services";
import Component from '../../../components'
import { formatDataHomePage } from "templates/Ncmaz/utils/function";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";


const HomePageCMS: React.FC = () => {

  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);  

  useEffect(() => {
    getData('/news-data')
      .then(res => {
        setIsLoading(false)
        if (res.data.length > 0) {

          const data = res.data.map((item: any) => {
            return {
              id: item.id,
              componentName: item.componentName,
              componentTitle: item.componentTitle,
              items: formatDataHomePage(item.items),
              shortDesc: item.shortDesc
            }
          })
          setPageData(data)
        }
      })
      .catch(err => setIsLoading(false))
    
    document.title = 'Lotusa CMS | Home Page'
  }, [])

  if (isLoading) return <LoadingScreen />


  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="relative overflow-hidden">

        {pageData.map((item: any, idx: number) => {
          return <div key={idx}>
            {idx === 2 && <SectionAds className="pt-16 lg:pt-24" />}
            {Component(item, true)}
          </div>
        })}


      </div>
    </div>
  );
};

export default HomePageCMS;
