/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import Tag from "../../components/Tag/Tag";
import SingleAuthor from "./SingleAuthor";
import SingleCommentForm from "./SingleCommentForm";
import SingleCommentLists from "./SingleCommentLists";
import SingleContentDemo from "./SingleContentDemo";
import { DEMO_TAGS } from "../../data/taxonomies";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import PostCardLikeAction from "../../components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "../../components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { PostAuthorType, TaxonomyType } from "templates/Ncmaz/data/types";
import { getData, postComment } from "templates/Ncmaz/utils/services";
import moment from "moment";
import { CommentType } from "templates/Ncmaz/components/CommentCard/CommentCard";

const demoTags = DEMO_TAGS.filter((_, i) => i < 9);

const formattedCommentsData = (data: any) => (
  data?.map((item: any) => ({
    id: item.id,
    date: moment(item.createdAt).format('MMM DD, YYYY'),
    content: item.content,
    like: {
      count: item.likeCount,
      isLiked: false,
    },
    author: {
      href: item?.author?.href,
      displayName: item?.author?.name,
      avatar: item?.author?.avatar?.url 
        ? (process.env.REACT_APP_URL_BE + item?.author?.avatar?.url)
        : item?.author?.avatar
    },
    children: formattedCommentsData(item.children)
  }))
)

export interface CommentContent {
  username?: string;
  email?: string;
  content?: string;
  threadOf?: number | null;
}
export interface SingleContentProps {
  newsId?: string | number;
  mainContent?: string;
  likeCount?: number;
  commentCount?: number;
  author?: PostAuthorType;
  tags?: TaxonomyType[];
}
export interface IPagination {
  page: number;
  pageSize: number;
  total: number;
}

const SingleContent: FC<SingleContentProps> = ({
  newsId,
  mainContent,
  likeCount = 0,
  commentCount = 0,
  author,
  tags = demoTags
}) => {
  const endedAnchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);
  const [flag, setFlag] = useState<number>(0)
  const [flagReply, setFlagReply] = useState<number>(0)
  const [commentData, setCommentData] = useState<CommentType[]>([])
  const [commentContent, setCommentContent] = useState<CommentContent>({
    content: '',
    email: '',
    username: '',
  })
  const [commentReplyContent, setCommentReplyContent] = useState<CommentContent>({
    threadOf: null,
    content: '',
    email: '',
    username: '',
  })
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pageSize: 2,
    total: 0
  })

  //
  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
  //
  const {pathname} = useLocation()

  const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
    threshold: 0,
    root: null,
    rootMargin: "0%",
    freezeOnceVisible: false,
  });

  useEffect(() => {
    const handleProgressIndicator = () => {
      const entryContent = contentRef.current;
      const progressBarContent = progressRef.current;

      if (!entryContent || !progressBarContent) {
        return;
      }

      const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let scrolled = (winScroll / totalEntryH) * 100;

      progressBarContent.innerText = scrolled.toFixed(0) + "%";

      if (scrolled >= 100) {
        setIsShowScrollToTop(true);
      } else {
        setIsShowScrollToTop(false);
      }
    };

    const handleProgressIndicatorHeadeEvent = () => {
      window?.requestAnimationFrame(handleProgressIndicator);
    };
    handleProgressIndicator();
    window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
    return () => {
      window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
    };
  }, []);

  useEffect(() => {
      getData(
          `/comments/v2/api::new.new:${newsId}?sort[0]=likeCount%3Adesc&sort[1]=updatedAt%3Adesc&populate[author][populate][avatar][select]=url&pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}`
      ).then((res) => {
          setCommentData(formattedCommentsData(res.data.data))
          setPagination((prevState) => ({...prevState, total: res.data.meta.pagination.total}))
      })
  }, [newsId])

  useEffect(() => {
      getData(
          `/comments/v2/api::new.new:${newsId}?sort[0]=likeCount%3Adesc&sort[1]=updatedAt%3Adesc&populate[author][populate][avatar][select]=url&pagination[page]=1&pagination[pageSize]=${pagination.pageSize*pagination.page+1}`
      ).then((res) => {
          setCommentData(formattedCommentsData(res.data.data))
      })
  }, [flag])

  useEffect(() => {
      getData(
          `/comments/v2/api::new.new:${newsId}?sort[0]=likeCount%3Adesc&sort[1]=updatedAt%3Adesc&populate[author][populate][avatar][select]=url&pagination[page]=1&pagination[pageSize]=${pagination.pageSize*pagination.page}`
      ).then((res) => {
          setCommentData(formattedCommentsData(res.data.data))
      })
  }, [flagReply])

  useEffect(() => {
    if (pagination.page === 1) return
      getData(
          `/comments/v2/api::new.new:${newsId}?sort[0]=likeCount%3Adesc&sort[1]=updatedAt%3Adesc&populate[author][populate][avatar][select]=url&pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}`
      ).then((res) => {
          setCommentData((prevState) => ([...prevState, ...formattedCommentsData(res.data.data)]))
      })
  }, [pagination])

  const showLikeAndCommentSticky =
    !endedAnchorEntry?.intersectionRatio &&
    (endedAnchorEntry?.boundingClientRect.top || 0) > 0;
  
  return (
    <div className="relative">
      <div className="nc-SingleContent space-y-10">
        {/* ENTRY CONTENT */}
        <div
          id="single-entry-content"
          className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          ref={contentRef}
        >
          <SingleContentDemo mainContent={mainContent}/>
        </div>

        {/* TAGS */}
        <div className="max-w-screen-md mx-auto flex flex-wrap">
          {tags.map((item) => (
            <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
          ))}
        </div>

        {/* AUTHOR */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="max-w-screen-md mx-auto ">
          <SingleAuthor author={author}/>
        </div>

        {/* COMMENT FORM */}
        <div
          id="comments"
          className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
        >
          <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
            Responses ({commentData.length})
          </h3>
          <SingleCommentForm
            threadOf={null}
            onClickSubmit={(e, threadOf) => {
              e.preventDefault();
              postComment('api::new.new', newsId, {
                  authorId: 123,
                  content: commentContent.content,
                  email: commentContent.email,
                  threadOf: threadOf,
                  username: commentContent.username,
              }).then(() => {
                setCommentContent({...commentContent, content: ''});
                setFlag(flag + 1)
              })
            }}
            value={commentContent}
            onChange={(key, value) => setCommentContent({...commentContent, [key]: value})}
            onClickCancel={() => setCommentContent({...commentContent, content: ''})}
          />
        </div>

        {/* COMMENTS LIST */}
        <div className="max-w-screen-md mx-auto">
          <SingleCommentLists
            pagination={pagination}
            comments={commentData}
            onSendReplyComment={(e, threadOf) => {
              e.preventDefault();
              postComment('api::new.new', newsId, {
                authorId: 123,
                content: commentReplyContent.content,
                email: commentReplyContent.email,
                threadOf: threadOf,
                username: commentReplyContent.username,
              }).then(() => {
                setCommentReplyContent({...commentReplyContent, content: ''});
                setFlagReply(flagReply + 1)
              })
            }}
            replyCommentContent={commentReplyContent}
            onChangeReply={(key, value) => setCommentReplyContent({...commentReplyContent, [key]: value})}
            handleShowMoreComments={pagination.total > pagination.page * pagination.pageSize ? () => {
              setPagination({...pagination, page: pagination.page + 1})
            } : () => {}}
          />
          <div ref={endedAnchorRef}></div>
        </div>
      </div>
      <div
        className={`sticky mt-8 bottom-8 z-40 justify-center ${
          showLikeAndCommentSticky ? "flex" : "hidden"
        }`}
      >
        <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
          <PostCardLikeAction likeCount={likeCount} className="px-3 h-9 text-xs" />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
          <PostCardCommentBtn
            isATagOnSingle
            className={` flex px-3 h-9 text-xs`}
            commentCount={commentCount}
            slug={pathname}
          />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

          <button
            className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
              isShowScrollToTop ? "flex" : "hidden"
            }`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="w-4 h-4" />
          </button>

          <button
            ref={progressRef}
            className={`w-9 h-9 items-center justify-center ${
              isShowScrollToTop ? "hidden" : "flex"
            }`}
            title="Go to top"
          >
            %
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleContent;
