import React, { FC } from "react";
import Tag from "../../components/Tag/Tag";
import WidgetHeading1 from "../../components/WidgetHeading1/WidgetHeading1";
import { DEMO_TAGS } from "../../data/taxonomies";
import { formattedDataTag } from "templates/Ncmaz/utils/function";

const tagsDemo = DEMO_TAGS.filter((_, i) => i < 9);

export interface IDataTag {
  title?: string,
  icon?: string,
  items?: any[],
}

export interface WidgetTagsProps {
  className?: string;
  data: IDataTag;
}

const WidgetTags: FC<WidgetTagsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  data,
}) => {
  const { title = 'More tags', items } = data

  return (
    <div className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title={`💡 ${title}`}
        viewAll={{ label: "View all", href: "/#" }}
      />
      <div className="flex flex-wrap p-4 xl:p-5">
        {items && formattedDataTag(items).map((tag: any) => (
          <Tag className="mr-2 mb-2" key={tag.id} tag={tag} />
        ))}
      </div>
    </div>
  );
};

export default WidgetTags;
