import React, { useEffect } from "react";

const SingleContentDemo = ({ mainContent }) => {
  useEffect(() => {
    if (!mainContent) return
    document.querySelector(".single-main-content").innerHTML = mainContent.replaceAll('../', '').replaceAll('uploads/', `${process.env.REACT_APP_URL_BE}/uploads/`);
  }, [mainContent])

  return (
    <div className="single-main-content"></div>
  );
};

export default SingleContentDemo;
