/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalCategories from "../ModalCategories";
import ModalTags from "../ModalTags";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ArchiveFilterListBox from "../../../components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "../../../components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "../../../components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "../../../components/SectionGridCategoryBox/SectionGridCategoryBox";
import ButtonSecondary from "../../../components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "../../../components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Card10V2 from "../../../components/Card10/Card10V2";
import { useParams } from "react-router-dom";
import { getData } from "templates/Ncmaz/utils/services";
import { formatDataCategories, formatDataCategoryDetailPage, formatDataPosts, formatDataTags } from "templates/Ncmaz/utils/function";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";
import Page404 from "../../not-found";
import { PostDataType } from "templates/Ncmaz/data/types";

const PageArchiveVideo = () => {
  const FILTERS = [
    { name: "Most Recent" },
    { name: "Curated by Admin" },
    { name: "Most Appreciated" },
    { name: "Most Discussed" },
    { name: "Most Viewed" },
  ];

  const [pageData, setPageData] = useState<any>();
  const [pageSettings, setPageSettings] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [trendingTopic, setTrendingTopic] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [error, setError] = useState(false);
  const [authors, setAuthors] = useState<any>([]);
  const [news, setNews] = useState<any>([]);
  const [paginationNews, setPaginationNews] = useState({
    start: 0,
    limit: 10,
  });
  const [paginationTrendingTopic, setPaginationTrendingTopic] = useState({
    start: 0,
    limit: 5,
  });
  const [openShowMore, setOpenShowMore] = useState({
    news: true,
    trendingTopic: true
  });

  const { slug } = useParams()

  const getPageData = (slug: string) => {
    getData(`/news/category-detail/${slug}`)
      .then(res => {
        setIsLoading(false)
        const data = formatDataCategoryDetailPage(res.data)
        const { categoryInfo, topAuthors, posts, trendingTopic } = data
        const { pageSetting } = res.data
        if (!categoryInfo.name) setError(true)
        setPageData(categoryInfo)
        setPageSettings(pageSetting)
        setNews(posts)
        setAuthors(topAuthors)
        setTrendingTopic(trendingTopic)
        setPaginationNews({ ...paginationNews, start: 0, limit: pageSetting.list.limit })
        setPaginationTrendingTopic({ ...paginationTrendingTopic, start: 0, limit: pageSetting.trending.limit })
      })
      .catch(err => {
        setIsLoading(false)
        setError(true)
      })
  }

  const getShowMoreNews = (slug: string, start: number, limit: number, layout: string) => {
    getData(`/category-detail-news/${slug}?start=${start}&limit=${limit}&layout=${layout}`)
      .then(res => {
        const data = formatDataPosts(res.data.news)
        if (data.length <= 0) {
          setOpenShowMore({ ...openShowMore, news: false })
        } else {
          setNews(news.concat(data))
          if (data.length <= paginationNews.limit) setOpenShowMore({ ...openShowMore, news: false })
        }
      })
      .catch(err => {
        console.log(err.message);

      })
  }

  const getShowMoreTrending = (start: number, layout: string) => {
    getData(`/trending-topic?start=${start}&layout=${layout}`)
      .then(res => {
        const data = formatDataCategories(res.data)
        if (data.length < 0) {
          setOpenShowMore({ ...openShowMore, trendingTopic: false })
        } else {
          setTrendingTopic(trendingTopic.concat(data))
          if (data.length < paginationTrendingTopic.limit) setOpenShowMore({ ...openShowMore, trendingTopic: false })
        }
      })
      .catch(err => {
        console.log(err.message);

      })
  }

  const getCategories = () => {
    getData(`/news/categories`)
      .then(res => {
        setCategories(formatDataCategories(res.data))
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  const getTags = () => {
    getData(`/news/tags`)
      .then(res => {
        setTags(formatDataTags(res.data))
      })
      .catch(err => console.log(err.message))
  }

  useEffect(() => {
    if (slug !== undefined) {
      getCategories()
      getTags()
      getPageData(slug)
    } else {
      setError(true)
    }
  }, [slug])  

  useEffect(() => {
    document.title = 'Lotusa CMS | Category Detail'
  }, [])

  if (isLoading) return <LoadingScreen />
  if (error) return <Page404 />

  return (
    <div className={`nc-PageArchiveVideo`}>
      <div className="dark bg-neutral-900/95 text-white">
        <div className="container py-16 lg:py-28 ">
          {/* HEADER */}
          <h2 className="inline-block align-middle text-5xl font-semibold md:text-6xl ">
            {pageData?.name}
          </h2>
          <span className="block mt-4 text-neutral-300">{pageSettings?.count} {pageSettings?.count <= 1 ? 'Video' : 'Videos'}</span>
          {/* ====================== END HEADER ====================== */}
          {pageSettings?.list?.active &&
            <>
              <div className="flex flex-col sm:justify-between sm:flex-row">
                <div className="flex space-x-2.5">
                  <ModalCategories categories={categories} kindCategory="news"/>
                  <ModalTags tags={tags} kindCategory="news"/>
                </div>
                <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                <div className="flex justify-end">
                  <ArchiveFilterListBox lists={FILTERS} />
                </div>
              </div>

              {/* LOOP ITEMS */}
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 md:gap-x-8 md:gap-y-10 mt-8 lg:mt-10">
                {news?.map((post: PostDataType) => (
                  <Card10V2 key={post.id} post={post} />
                ))}
                {news?.length === 0 && <p>No Items Found</p>}
              </div>

              {/* PAGINATIONS */}
              {openShowMore.news && <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
                {/* <Pagination /> */}
                <ButtonPrimary
                  onClick={() => {
                    setPaginationNews({ ...paginationNews, start: paginationNews.start + paginationNews.limit })
                    if (slug !== undefined) {
                      getShowMoreNews(slug, paginationNews.start + paginationNews.limit, paginationNews.limit, pageData.layout)
                    }
                  }}
                >
                  Show me more
                </ButtonPrimary>
              </div>}
            </>}
        </div>
      </div>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        {
          pageSettings?.authors?.active && <SectionSliderNewAuthors
            heading={pageSettings?.authors?.activeHeader ? pageSettings?.authors?.title : ' '}
            subHeading={pageSettings?.authors?.activeHeader ? pageSettings?.authors?.desc : ' '}
            authors={authors || []}
          />
        }

        {/* === SECTION 5 === */}
        {pageSettings?.trending?.active &&
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridCategoryBox
              title={pageSettings?.trending?.activeHeader ? pageSettings?.trending?.title : ' '}
              desc={pageSettings?.trending?.activeHeader ? pageSettings?.trending?.desc : ' '}
              categories={trendingTopic || []}
            />
            {openShowMore.trendingTopic &&
              <div className="text-center mx-auto mt-10 md:mt-16">
                <ButtonSecondary
                  onClick={() => {
                    setPaginationTrendingTopic({ ...paginationTrendingTopic, start: paginationTrendingTopic.start + paginationTrendingTopic.limit })
                    if (slug !== undefined) {
                      getShowMoreTrending(paginationTrendingTopic.start + paginationTrendingTopic.limit, pageData.layout)
                    }
                  }}
                >
                  Show me more
                </ButtonSecondary>
              </div>}
          </div>}

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageArchiveVideo;
