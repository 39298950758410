/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalCategories from "../ModalCategories";
import ModalTags from "../ModalTags";
import { PostDataType } from "../../../data/types";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ArchiveFilterListBox from "../../../components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "../../../components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "../../../components/NcImage/NcImage";
import BackgroundSection from "../../../components/BackgroundSection/BackgroundSection";
import SectionSliderNewAuthors from "../../../components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Card16Podcast from "../../../components/Card16Podcast/Card16Podcast";
import Card15Podcast from "../../../components/Card15Podcast/Card15Podcast";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";
import Page404 from "../../not-found";
import { formatDataCategories, formatDataCategoryDetailPage, formatDataPosts, formatDataTags } from "templates/Ncmaz/utils/function";
import { useParams } from "react-router-dom";
import { getData } from "templates/Ncmaz/utils/services";
import SectionGridCategoryBox from "templates/Ncmaz/components/SectionGridCategoryBox/SectionGridCategoryBox";

const PageArchiveAudio = () => {
  const FILTERS = [
    { name: "Most Recent" },
    { name: "Curated by Admin" },
    { name: "Most Appreciated" },
    { name: "Most Discussed" },
    { name: "Most Viewed" },
  ];

  const [pageData, setPageData] = useState<any>();
  const [pageSettings, setPageSettings] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [trendingTopic, setTrendingTopic] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [error, setError] = useState(false);
  const [authors, setAuthors] = useState<any>([]);
  const [news, setNews] = useState<any>([]);
  const [paginationNews, setPaginationNews] = useState({
    start: 0,
    limit: 10,
  });
  const [paginationTrendingTopic, setPaginationTrendingTopic] = useState({
    start: 0,
    limit: 5,
  });
  const [openShowMore, setOpenShowMore] = useState({
    news: true,
    trendingTopic: true
  });

  const { slug } = useParams()

  const getPageData = (slug: string) => {
    getData(`/news/category-detail/${slug}`)
      .then(res => {
        setIsLoading(false)
        const data = formatDataCategoryDetailPage(res.data)
        const { categoryInfo, topAuthors, posts, trendingTopic } = data
        const { pageSetting } = res.data
        if (!categoryInfo.name) setError(true)
        setPageData(categoryInfo)
        setPageSettings(pageSetting)
        setNews(posts)
        setAuthors(topAuthors)
        setTrendingTopic(trendingTopic)
        setPaginationNews({ ...paginationNews, start: 0, limit: pageSetting.list.limit })
        setPaginationTrendingTopic({ ...paginationTrendingTopic, start: 0, limit: pageSetting.trending.limit })
      })
      .catch(err => {
        setIsLoading(false)
        setError(true)
      })
  }

  const getShowMoreNews = (slug: string, start: number, limit: number, layout: string) => {
    getData(`/category-detail-news/${slug}?start=${start}&limit=${limit}&layout=${layout}`)
      .then(res => {
        const data = formatDataPosts(res.data.news)
        if (data.length <= 0) {
          setOpenShowMore({ ...openShowMore, news: false })
        } else {
          setNews(news.concat(data))
          if (data.length < paginationNews.limit) setOpenShowMore({...openShowMore, news: false})
        }
      })
      .catch(err => {
        console.log(err.message);

      })
  }

  const getShowMoreTrending = ( start: number, layout: string) => {
    getData(`/trending-topic?start=${start}&layout=${layout}`)
      .then(res => {
        const data = formatDataCategories(res.data)
        if (data.length <= 0) {
          setOpenShowMore({ ...openShowMore, trendingTopic: false })
        } else {
          setTrendingTopic(trendingTopic.concat(data))
          if (data.length < paginationTrendingTopic.limit) setOpenShowMore({...openShowMore, trendingTopic: false})
        }
      })
      .catch(err => {
        console.log(err.message);

      })
  }

  const getCategories = () => {
    getData(`/news/categories`)
      .then(res => {
        setCategories(formatDataCategories(res.data))
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  const getTags = () => {
    getData(`/news/tags`)
      .then(res => {
        setTags(formatDataTags(res.data))
      })
      .catch(err => console.log(err.message))
  }

  useEffect(() => {
    if (slug !== undefined) {
      getCategories()
      getTags()
      getPageData(slug)
    } else {
      setError(true)
    }
  }, [slug])

  useEffect(() => {
    document.title = 'Lotusa CMS | Category Detail'
  }, [])

  if (isLoading) return <LoadingScreen />
  if (error) return <Page404 />


  const renderSection = (sectionPosts: PostDataType[]) => {
    const loopPosts = sectionPosts.filter((_, i) => i > 2);
    return (
      <div className="mt-8 lg:mt-10 grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        {sectionPosts[0] && <Card16Podcast post={sectionPosts[0]} />}
        {sectionPosts[1] && <Card16Podcast post={sectionPosts[1]} />}
        {sectionPosts[2] && <Card16Podcast post={sectionPosts[2]} />}
        <div className="md:col-span-2 lg:col-span-3">
          <div className={`grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8`}>
            {loopPosts.map((p, i) => (
              <Card15Podcast key={i} post={p} />
            ))}
          </div>
        </div>
      </div>
    );
  };  

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl relative aspect-w-16 aspect-h-12 sm:aspect-h-7 lg:aspect-h-6 xl:aspect-h-5 2xl:aspect-h-4 overflow-hidden z-0">
          <NcImage
            alt="archive"
            fill
            containerClassName="absolute inset-0"
            src={pageData?.thumbnail}
            className="object-cover w-full h-full"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle ml-3 text-5xl font-semibold md:text-7xl ">
              {pageData?.name}
            </h2>
            <span className="block mt-4 text-neutral-300">{pageSettings?.count} Podcast</span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">/news
        <div>
          {
            pageSettings?.list?.active &&
            <>
              <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                <div className="flex space-x-2.5">
                  <ModalCategories categories={categories} kindCategory="news"/>
                  <ModalTags tags={tags} kindCategory="news"/>
                </div>
                <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                <div className="flex justify-end">
                  <ArchiveFilterListBox lists={FILTERS} />
                </div>
              </div>

              {/* LOOP ITEMS */}
              {renderSection(news)}

              {/* PAGINATIONS */}
              <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
                {/* <Pagination /> */}
                {
                  openShowMore.news && <ButtonPrimary
                    onClick={() => {
                      setPaginationNews({ ...paginationNews, start: paginationNews.start + paginationNews.limit })
                      if (slug !== undefined) {
                        getShowMoreNews(slug, paginationNews.start + paginationNews.limit, paginationNews.limit, pageData.layout)
                      }
                    }}
                  >
                    Show me more
                  </ButtonPrimary>
                }
              </div>
            </>
          }
        </div>

        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        {pageSettings?.authors?.active &&
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewAuthors
              heading={pageSettings?.authors?.activeHeader ? pageSettings?.authors?.title : ' '}
              subHeading={pageSettings?.authors?.activeHeader ? pageSettings?.authors?.desc : ' '}
              authors={authors || []}
            />
          </div>}

        {/* === Top trending === */}
        {pageSettings?.trending?.active &&
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridCategoryBox
              title={pageSettings?.trending?.activeHeader ? pageSettings?.trending?.title : ' '}
              desc={pageSettings?.trending?.activeHeader ? pageSettings?.trending?.desc : ' '}
              categories={trendingTopic || []}
            />
            {openShowMore.trendingTopic && <div className="text-center mx-auto mt-10 md:mt-16">
              <ButtonPrimary
                onClick={() => {
                  setPaginationTrendingTopic({ ...paginationTrendingTopic, start: paginationTrendingTopic.start + paginationTrendingTopic.limit })
                  if (slug !== undefined) {
                    getShowMoreTrending(paginationTrendingTopic.start + paginationTrendingTopic.limit, pageData.layout)
                  }
                }}
              >
                Show me more
              </ButtonPrimary>

              {/* <ButtonSecondary loading>Show me more</ButtonSecondary> */}
            </div>}
          </div>}

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageArchiveAudio;
