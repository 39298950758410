/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NcImage from "../../../../components/NcImage/NcImage";
import SingleHeader from "../../../../app/(singles)/SingleHeader";
import Layout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "templates/Ncmaz/utils/services";
import { formatDataNewDetail, formatNewDataArr } from "templates/Ncmaz/utils/function";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";

const PageSingleTemplate2 = () => {
  const [newsDetail, setNewsDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [relatedNewsData, setRelatedNewsData] = useState([])
  const [moreFromAuthorData, setMoreFromAuthorData] = useState([])
  const [widgetData, setWidgetData] = useState([])
  const { slug } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    getData(`/news-detail/${slug}`)
      .then((res) => {
        setNewsDetail(formatDataNewDetail(res.data.entity))
        setRelatedNewsData(formatNewDataArr(res.data.relatedNews))
        setMoreFromAuthorData(formatNewDataArr(res.data.moreFromAuthor))
        setWidgetData(res.data.widget)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err, 'error')
        setIsLoading(false)
        navigate('/page404')
      })
  }, [slug, navigate])

  useEffect(() => {
    document.title = 'Lotusa CMS | News Detail'
  }, [])

  if (isLoading) return <LoadingScreen />

  return (
    <Layout
      newsDetail={newsDetail}
      relatedNews={relatedNewsData}
      moreFromAuthor={moreFromAuthorData}
      widget={widgetData}
    >
      <div className={`pt-8 lg:pt-16`}>
        {/* SINGLE HEADER */}
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader hiddenDesc newsDetail={newsDetail} />
          </div>
        </header>

        <div className="">
          <NcImage
            alt=""
            containerClassName="my-10 sm:my-12 relative aspect-w-16 aspect-h-12 md:aspect-h-9 lg:aspect-h-6"
            className="absolute inset-0 object-cover w-full"
            fill
            sizes="100vw"
            src={newsDetail?.featuredImage || "https://images.pexels.com/photos/347141/pexels-photo-347141.jpeg"}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PageSingleTemplate2;
