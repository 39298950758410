import React, { FC, useEffect, useState } from "react";
import Card2 from "../Card2/Card2";
import { SectionMagazine1Props } from "../Sections/SectionMagazine1";
import HeaderFilter from "../Sections/HeaderFilter";
import Card11 from "../Card11/Card11";
import { getData } from "templates/Ncmaz/utils/services";
import { formatDataTabs } from "templates/Ncmaz/utils/function";

export interface SectionMagazine2Props extends SectionMagazine1Props { }

const Component: FC<SectionMagazine2Props> = ({
  posts,
  heading = 'Latest Articles',
  className,
  desc
}) => {
  const [tabs, setTabs] = useState<any>(['All Items']);


  const getTags = async () => {
    getData(`/category?populate=deep,2`)
      .then(res => {
        let copyRes = [...formatDataTabs(res.data.data)]
        setTabs(tabs.concat(copyRes))
      })
  }

  useEffect(() => {
    getTags()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`nc-SectionMagazine2 ${className}`}>
      <HeaderFilter heading={heading} tabs={tabs} desc={desc} />

      {!posts.length && <span>Nothing we found!</span>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div className="grid gap-6">
          {posts
            .filter((_, i) => i < 3 && i > 0)
            .map((item, index) => {
              return (
                <Card11 ratio="aspect-w-5 aspect-h-3" key={index} post={item} />
              );
            })}
        </div>
        <div className="lg:col-span-2">
          {posts[0] && <Card2 size="large" post={posts[0]} />}
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-1 md:col-span-3 xl:col-span-1">
          {posts
            .filter((_, i) => i < 5 && i >= 3)
            .map((item, index) => {
              return (
                <Card11 ratio="aspect-w-5 aspect-h-3" key={index} post={item} />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Component;
