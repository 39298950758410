/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalCategories from "../ModalCategories";
import ModalTags from "../ModalTags";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ArchiveFilterListBox from "../../../components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "../../../components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "../../../components/Card11/Card11";
import BackgroundSection from "../../../components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "../../../components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionSliderNewAuthors from "../../../components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Image from "../../../components/Image";
import { useParams } from "react-router-dom";
import { getData } from "templates/Ncmaz/utils/services";
import { defaultThumbnail, formatDataCategories, formatDataPosts, formatDataTagDetailPage, formatDataTags } from "templates/Ncmaz/utils/function";
import Page404 from "../../not-found";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";

// Tag and category have same data type - we will use one demo data

const TagPage = () => {
  const FILTERS = [
    { name: "Most Recent" },
    { name: "Curated by Admin" },
    { name: "Most Appreciated" },
    { name: "Most Discussed" },
    { name: "Most Viewed" },
  ];
  const [pageData, setPageData] = useState<any>();
  const [pageSettings, setPageSettings] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [trendingTopic, setTrendingTopic] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [error, setError] = useState(false);
  const [authors, setAuthors] = useState<any>([]);
  const [news, setNews] = useState<any>([]);


  const [paginationNews, setPaginationNews] = useState({
    start: 0,
    limit: 10,
  });
  const [paginationTrendingTopic, setPaginationTrendingTopic] = useState({
    start: 0,
    limit: 5,
  });
  const [openShowMore, setOpenShowMore] = useState({
    news: true,
    trendingTopic: true
  });

  const { slug } = useParams()

  const getPageData = (slug: string) => {
    getData(`/news/tag-detail/${slug}`)
      .then(res => {
        setIsLoading(false)
        const data = formatDataTagDetailPage(res.data)
        const { tagInfo, topAuthors, posts, trendingTopic } = data
        const { pageSetting } = res.data
        if (!tagInfo.name) setError(true)
        setPageData(tagInfo)
        setPageSettings(pageSetting)
        setNews(posts)
        setAuthors(topAuthors)
        setTrendingTopic(trendingTopic)
        setPaginationNews({ ...paginationNews, start: 0, limit: pageSetting.list.limit })
        setPaginationTrendingTopic({ ...paginationTrendingTopic, start: 0, limit: pageSetting.trending.limit })
      })
      .catch(err => {
        setIsLoading(false)
        setError(true)
      })
  }
  

  const getShowMoreNews = (slug: string, start: number, limit: number, layout: string) => {
    getData(`/tag-detail-news/${slug}?start=${start}&limit=${limit}&layout=${layout}`)
      .then(res => {
        const data = formatDataPosts(res.data.news)
        if (data.length <= 0) {
          setOpenShowMore({ ...openShowMore, news: false })
        } else {
          setNews(news.concat(data))
          if (data.length < paginationNews.limit) setOpenShowMore({...openShowMore, news: false})
        }
      })
      .catch(err => {
        console.log(err.message);

      })
  }

  const getShowMoreTrending = (start: number, layout: string) => {
    getData(`/trending-topic?start=${start}&layout=${layout}`)
      .then(res => {
        const data = formatDataCategories(res.data)
        if (data.length <= 0) {
          setOpenShowMore({ ...openShowMore, trendingTopic: false })
        } else {
          setTrendingTopic(trendingTopic.concat(data))
          if (data.length < paginationTrendingTopic.limit) setOpenShowMore({...openShowMore, trendingTopic: false})
        }
      })
      .catch(err => {
        console.log(err.message);

      })
  }

  const getCategories = () => {
    getData(`/news/categories`)
      .then(res => {
        setCategories(formatDataCategories(res.data))
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  const getTags = () => {
    getData(`/news/tags`)
      .then(res => {
        setTags(formatDataTags(res.data))
      })
      .catch(err => console.log(err.message))
  }

  useEffect(() => {
    if (slug !== undefined) {
      getCategories()
      getTags()
      getPageData(slug)
    } else {
      setError(true)
    }
  }, [slug])

  useEffect(() => {
    document.title = 'Lotusa CMS | Tag Detail'
  }, [])

  if (isLoading) return <LoadingScreen />
  if (error) return <Page404 />

  return (
    <div className={`nc-PageArchive`}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
          <Image
            alt="archive"
            fill
            src={'https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'}
            className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {pageData?.name}
            </h2>
            <span className="block mt-4 text-neutral-300">{pageSettings?.count} Articles</span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        {pageSettings?.list?.active
          ? <div>
              <div className="flex flex-col sm:justify-between sm:flex-row">
                <div className="flex space-x-2.5">
                  <ModalCategories categories={categories || []} kindCategory="news"/>
                  <ModalTags tags={tags || []} kindCategory="news"/>
                </div>
                <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                <div className="flex justify-end">
                  <ArchiveFilterListBox lists={FILTERS} />
                </div>
              </div>

              {/* LOOP ITEMS */}
              <div className={news?.length > 0 ? "grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10" : 'text-center'}>
                {news?.length > 0 ? news?.map((post: any) => (
                  <Card11 key={post.id} post={post} />
                )) : <div className="w-full mt-10">No Items Found!</div>}
              </div>

              {/* PAGINATIONS */}
              {openShowMore.news && <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
                {/* <Pagination /> */}
                <ButtonPrimary
                  onClick={() => {
                    setPaginationNews({ ...paginationNews, start: paginationNews.start + paginationNews.limit })
                    if (slug !== undefined) {
                      getShowMoreNews(slug, paginationNews.start + paginationNews.limit, paginationNews.limit, 'card')
                    }
                  }}
                >
                  Show me more
                </ButtonPrimary>
              </div>}
            </div>
          : null
        }

        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        {pageSettings?.trending?.active &&
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridCategoryBox
              title={pageSettings?.trending?.activeHeader ? pageSettings?.trending?.title : ' '}
              desc={pageSettings?.trending?.activeHeader ? pageSettings?.trending?.desc : ' '}
              categories={trendingTopic || []}
            />
            {openShowMore.trendingTopic && <div className="text-center mx-auto mt-10 md:mt-16">
              <ButtonPrimary
                onClick={() => {
                  setPaginationTrendingTopic({ ...paginationTrendingTopic, start: paginationTrendingTopic.start + paginationTrendingTopic.limit })
                  if (slug !== undefined) {
                    getShowMoreTrending(paginationTrendingTopic.start + paginationTrendingTopic.limit, 'card')
                  }
                }}
              >
                Show me more
              </ButtonPrimary>

              {/* <ButtonSecondary loading>Show me more</ButtonSecondary> */}
            </div>}
          </div>}

        {/* === SECTION 5 === */}
        {
          pageSettings?.authors?.active &&
          <SectionSliderNewAuthors
            heading={pageSettings?.authors?.activeHeader ? pageSettings?.authors?.title : ' '}
            subHeading={pageSettings?.authors?.activeHeader ? pageSettings?.authors?.desc : ' '}
            authors={authors || []}
          />
        }

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default TagPage;
