import { NAVIGATION_DEMO_2 } from '../../data/navigation'
import React, { useEffect, useState, FC } from 'react'
import axios from 'axios'
import _ from 'lodash'
import NavigationItem from './NavigationItem'

interface Props {
    className?: string
}
interface NavInfo {
    id: number
    menuItem: any
}

const Navigation: FC<Props> = ({ className = 'flex' }) => {
    const randomId = _.uniqueId
    const [navInfo, setNavInfo] = useState<NavInfo[] | []>([])
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/navigation/render/lotusa-tem-nav?type=TREE`
            )
            .then((res) => {
                const navItems = res.data.map((data: any) => {
                    const dataInfo: any = {
                        id: randomId(),
                        href: data.path,
                        name: data.title,
                        type: data.megaMenu
                            ? data.items.length > 0
                                ? 'megaMenu'
                                : null
                            : data.items.length > 0
                            ? 'dropdown'
                            : null,
                    }
                    if (data.items.length > 0 && dataInfo.type) {
                        dataInfo.children = data.items.map(
                            (item: any, idx: number) => {
                                if (idx < 4) {
                                    const itemInfo: any = {
                                        id: randomId(),
                                        isNew: item.isNew,
                                        href: item.path,
                                        name: item.title,
                                        type:
                                            dataInfo.type === 'megaMenu'
                                                ? null
                                                : item.items.length > 0
                                                ? 'dropdown'
                                                : null,
                                    }
                                    if (
                                        item.items.length > 0 &&
                                        (itemInfo.type ||
                                            dataInfo.type === 'megaMenu')
                                    ) {
                                        itemInfo.children = item.items.map(
                                            (childItem: any, idx: number) => {
                                                if (idx < 4) {
                                                    const childItemInfo = {
                                                        id: randomId(),
                                                        isNew: childItem.isNew,
                                                        href: childItem.path,
                                                        name: childItem.title,
                                                    }
                                                    return childItemInfo
                                                }
                                            }
                                        )
                                    }
                                    return itemInfo
                                }
                            }
                        )
                    }

                    return dataInfo
                })
                setNavInfo(navItems)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <ul className={`nc-Navigation items-center ${className}`}>
            {navInfo.map((item: any) => (
                <NavigationItem key={item.id} menuItem={item} />
            ))}
        </ul>
    )
}

export default Navigation
