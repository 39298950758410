import React, { useEffect } from 'react'
import Input from '../../../components/Input/Input'
import ButtonPrimary from '../../../components/Button/ButtonPrimary'
import NcLink from '../../../components/NcLink/NcLink'
import Heading2 from '../../../components/Heading/Heading2'
import Layout from '../layout'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alert } from '../signup/page'

const PageResetPass = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [open, setOpen] = React.useState<string | boolean>(false)
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, 'Minimum 8 characters')
                .required('Required!'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], "Password's not match")
                .required('Required!'),
        }),
        onSubmit: (values) => {
            const url = `${process.env.REACT_APP_API_URL}/auth/reset-password`
            const body = {
                password: values.password,
                passwordConfirmation: values.confirm_password,
                code: searchParams.get('code'),
            }
            axios
                .post(url, body)
                .then((res) => {
                    // localStorage.setItem('expireToken', res?.data?.jwt)
                    formik.values.password = ''
                    formik.values.confirm_password = ''
                    setOpen('success')
                    // handleClose()
                    // navigate('/')
                })
                .catch((err) => {
                    setOpen('fail')
                    console.log(err)
                })
        },
    })
    const displayToast = (key: string, color: AlertColor, message: string) => {
        return (
            <Snackbar
                open={open === key}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={color}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        )
    }
    useEffect(() => {
        document.title = 'Lotusa CMS | Reset Password'
      }, [])
    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
                <Heading2>Reset password</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Welcome to our blog magazine Community
                </span>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                {/* FORM */}
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-cols-1 gap-6"
                >
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                            Password
                        </span>
                        <Input
                            type="password"
                            className="mt-1"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.password && formik.touched.password && (
                            <p className="text-xs text-red-500 mt-1">
                                {formik.errors.password}
                            </p>
                        )}
                    </label>
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                            Confirm Password
                        </span>
                        <Input
                            type="password"
                            className="mt-1"
                            name="confirm_password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.confirm_password &&
                            formik.touched.confirm_password && (
                                <p className="text-xs text-red-500 mt-1">
                                    {formik.errors.confirm_password}
                                </p>
                            )}
                    </label>

                    <ButtonPrimary type="submit">Continue</ButtonPrimary>
                </form>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    Go back for {` `}
                    <NcLink href="/login">Sign in</NcLink>
                    {` / `}
                    <NcLink href="/signup">Sign up</NcLink>
                </span>
            </div>
            {displayToast(
                'success',
                'success',
                'Reset password success. Go to login page!'
            )}
            {displayToast(
                'fail',
                'error',
                'Reset password fail! Please try again!'
            )}
        </Layout>
    )
}

export default PageResetPass
