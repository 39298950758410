import React, { FC } from 'react'
import IconComponent from 'templates/Ncmaz/utils/IconComponent'

interface NoResultProp {
    message?: string
}

const NoResultPage: FC<NoResultProp> = ({ message = 'Not Found' }) => {
    return (
        <div className="w-full h-full flex min-h-20 items-center justify-center flex-col">
            <IconComponent icon={'FaSearch'} size={40} />
            <div className="text-2xl pt-4">{message}</div>
        </div>
    )
}

export default NoResultPage
