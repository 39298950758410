import React, { FC } from "react";
import Heading from "../../components/Heading/Heading";
import { PostAuthorType } from "../../data/types";
import CardAuthorBox2 from "../../components/CardAuthorBox2/CardAuthorBox2";
import MySlider from "../../components/MySlider";

export interface SectionSliderNewAuthorsProps {
  className?: string;
  heading: string;
  subHeading: string;
  authors: PostAuthorType[];
  itemPerRow?: number; 
}

const SectionSliderNewAuthors: FC<SectionSliderNewAuthorsProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  authors,
  itemPerRow = 5,
}) => {

  let itemsPerRow = 5

  itemsPerRow = authors.length <= 5 ? authors.length : itemPerRow

  return (
    <div className={`nc-SectionSliderNewAuthors ${className}`}>
      <Heading desc={subHeading} isCenter>
        {heading}
      </Heading>
      <MySlider
        itemPerRow={itemsPerRow}
        data={authors}
        renderItem={(item, index) => (
          <CardAuthorBox2 key={index} author={item} />
        )}
      />
    </div>
  );
};

export default SectionSliderNewAuthors;
