import Card15Podcast from "../../components/Card15Podcast/Card15Podcast";
import Card9 from "../../components/Card9/Card9";
import Heading from "../../components/Heading/Heading";
import { PostDataType } from "../../data/types";
import  { FC } from "react";

export interface SectionMagazine9Props {
  posts: PostDataType[];
  className?: string;
  gapClassName?: string;
  heading?: string;
  desc?: string;
}

const Component: FC<SectionMagazine9Props> = ({
  posts,
  className = "pt-16 lg:pt-24",
  gapClassName = "gap-6 md:gap-8",
  heading = "",
  desc = "Click on music icon and enjoy music or podcast"
}) => { 

  return (
    <div className={`nc-SectionMagazine9 relative ${className}`}>
      {heading && (
        <Heading desc={desc}>
          {heading}
        </Heading>
      )}
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${gapClassName}`}
      >
        {posts[0] && <Card9 ratio="aspect-w-4 aspect-h-3" post={posts[0]} />}
        {posts[1] && <Card9 ratio="aspect-w-4 aspect-h-3" post={posts[1]} />}
        {posts[2] && <Card9 ratio="aspect-w-4 aspect-h-3" post={posts[2]} />}
      </div>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${gapClassName} mt-8`}
      >
        {posts
          .filter((_, i) => i > 2)
          .map((p, index) => (
            <Card15Podcast key={index} post={p} />
          ))}
      </div>
    </div>
  );
};

export default Component;
