import axios from "axios"

export const getData = async (url) => {
    return await axios.get(process.env.REACT_APP_API_URL + url)
}

export const postComment = async (apiName = '', articleId, dataSend) => {
    const headers = {
        "Content-Type": "application/json",
    }
    const url = process.env.REACT_APP_API_URL + `/comments/${apiName}:${articleId}`
    const data = {
        content: dataSend.content,
        threadOf: dataSend.threadOf
    }
    // if (localStorage.getItem('expireToken')) {
    //     headers = Object.assign(
    //         { Authorization: 'Bearer ' + localStorage.getItem('expireToken') },
    //         headers
    //     )
    //     data = Object.assign(
    //         {
    //             author: {
    //                 id: dataSend.authorId,
    //                 name: dataSend.username,
    //                 email: dataSend.email,
    //                 avatar: dataSend.avatar || '/logo512.png',
    //             },
    //         },
    //         data
    //     ) 
    // }

    return await axios.request({
        method: 'POST',
        url,
        headers: {
            ...headers,
            ...(localStorage.getItem('expireToken')
                ? {
                    Authorization:
                        'Bearer ' + localStorage.getItem('expireToken'),
                }
                : {}),
        },
        data: {
            ...data,
            ...(!localStorage.getItem('expireToken')
                ? {
                    author: {
                        id: dataSend.authorId,
                        name: dataSend.username,
                        email: dataSend.email,
                        avatar: dataSend.avatar || '/logo512.png',
                    },
                }
                : {}),
        },
    })
}

export const getIP = async () => {
    return await axios.get('https://api.ipify.org/?format=json')
}

export const putData = async (url, data) => {
    return await axios.put(process.env.REACT_APP_API_URL + url, { data: data })
}

export const getUserMac = async () => {
    return await axios.get(process.env.REACT_APP_API_URL + '/userMac')
}

export const getProfile = async (token) => {

    let headersList = {
        "Authorization": `Bearer ${token}`
    }

    let reqOptions = {
        url: process.env.REACT_APP_API_URL + "/users/me?populate=deep,2",
        method: "GET",
        headers: headersList,
    }

    return await axios.request(reqOptions);
}

export const uploadImage = async (token, image) => {
    const formData = new FormData()
    formData.append('files', image)

    return await axios.request({
        url: process.env.REACT_APP_API_URL + "/upload",
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        },
        data: formData
    })
}

export const updateUserAvatar = async (userId, token, imageId) => {

    return await axios.request({
        url: process.env.REACT_APP_API_URL + "/users/" + userId,
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${token}`
        },
        data: {
            avatar: imageId
        }
    })
}

export const updateProfile = async (userId, token, data) => {
    return await axios.request({
        url: process.env.REACT_APP_API_URL + "/users/" + userId,
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${token}`
        },
        data: data
    })
}

export const changePassword = async (token, currentPassword, newPassword, passwordConfirm) => {

    return await axios.post(
        process.env.REACT_APP_API_URL + '/auth/change-password',
        {
            currentPassword: currentPassword,
            password: newPassword,
            passwordConfirmation: passwordConfirm,
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    )

}