import React, { FC } from "react";
import PostCardCommentBtn from "../PostCardCommentBtn/PostCardCommentBtn";
import PostCardLikeAction from "../PostCardLikeAction/PostCardLikeAction";

export interface PostCardLikeAndCommentProps {
  className?: string;
  itemClass?: string;
  hiddenCommentOnMobile?: boolean;
  useOnSinglePage?: boolean;
  likeCount?: number;
  liked?: boolean;
  commentCount?: number;
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
  className = "",
  itemClass = "px-3 h-8 text-xs",
  hiddenCommentOnMobile = true,
  useOnSinglePage = false,
  commentCount = 0,
  likeCount = 0,
  liked = false
}) => {
  return (
    <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}
    >
      <PostCardLikeAction
        className={itemClass}
        likeCount={likeCount}
        liked={liked}
      />
      <PostCardCommentBtn
        commentCount={commentCount}
        className={`${
          hiddenCommentOnMobile ? "hidden sm:flex" : "flex"
        }  ${itemClass}`}
        isATagOnSingle={useOnSinglePage}
      />
    </div>
  );
};

export default PostCardLikeAndComment;
