import React, { FC, ReactNode } from 'react'
import SingleContent from './SingleContent'
import SingleRelatedPosts from './SingleRelatedPosts'
import { PostDataType } from 'templates/Ncmaz/data/types'
import { DEMO_TAGS } from 'templates/Ncmaz/data/taxonomies'
import { Sidebar } from './Sidebar'

export class ILayoutProps {
    children?: ReactNode
    newsDetail?: PostDataType
    relatedNews?: PostDataType[]
    moreFromAuthor?: PostDataType[]
    widget?: any[]
}

const demoTags = DEMO_TAGS.filter((_, i) => i < 9)

const Layout: FC<ILayoutProps> = ({
    children,
    newsDetail,
    relatedNews,
    moreFromAuthor,
    widget,
}) => {
    
    return (
        <div>
            {children}

            {/* SINGLE MAIN CONTENT */}
            <div className="container flex flex-col my-10 lg:flex-row ">
                <div
                    className={`w-full ${
                        widget && widget?.length > 0
                            ? 'lg:w-3/5 xl:w-2/3 xl:pr-20'
                            : ''
                    }`}
                >
                    <SingleContent
                        newsId={newsDetail?.id}
                        author={newsDetail?.author}
                        commentCount={newsDetail?.commentCount}
                        likeCount={newsDetail?.like.count}
                        mainContent={newsDetail?.mainContent}
                        tags={newsDetail?.tags || demoTags}
                    />
                </div>
                {widget && widget?.length > 0 && (
                    <div className='w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3'>
                        <Sidebar widget={widget} />
                    </div>
                )}
            </div>

            {/* RELATED POSTS */}
            <SingleRelatedPosts
                relatedPosts={relatedNews}
                moreFromAuthorPosts={moreFromAuthor}
            />
        </div>
    )
}

export default Layout
