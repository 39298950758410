import React, { useEffect } from 'react'
import Input from '../../../components/Input/Input'
import ButtonPrimary from '../../../components/Button/ButtonPrimary'
import NcLink from '../../../components/NcLink/NcLink'
import Heading2 from '../../../components/Heading/Heading2'
import Layout from '../layout'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alert } from '../signup/page'

const PageForgotPass = () => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState<string | boolean>(false)
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .required('Required!')
                .matches(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    'Please type valid email!'
                ),
        }),
        onSubmit: (values) => {
            const url = `${process.env.REACT_APP_API_URL}/auth/forgot-password`
            const body = { email: values.email }
            axios
                .post(url, body)
                .then((res) => {
                    // localStorage.setItem('expireToken', res?.data?.jwt)
                    console.log(res)
                    formik.values.email = ''
                    setOpen('success')
                    // handleClose()
                    // navigate('/')
                })
                .catch((err) => {
                    setOpen('fail')
                    console.log(err)
                })
        },
    })
    const displayToast = (key: string, color: AlertColor, message: string) => {
        return (
            <Snackbar
                open={open === key}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={color}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        )
    }
    useEffect(() => {
        document.title = 'Lotusa CMS | Forgot Password'
      }, [])
    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
                <Heading2>Forgot password</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Welcome to our blog magazine Community
                </span>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                {/* FORM */}
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-cols-1 gap-6"
                >
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                        </span>
                        <Input
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.email && formik.touched.email && (
                            <p className="text-xs text-red-500 mt-1">
                                {formik.errors.email}
                            </p>
                        )}
                    </label>

                    <ButtonPrimary type="submit">Continue</ButtonPrimary>
                </form>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    Go back for {` `}
                    <NcLink href="/login">Sign in</NcLink>
                    {` / `}
                    <NcLink href="/signup">Sign up</NcLink>
                </span>
            </div>
            {displayToast(
                'success',
                'success',
                'We sent link verify to your email. Please click link to reset password'
            )}
            {displayToast('fail', 'error', 'Login fail! Please try again!')}
        </Layout>
    )
}

export default PageForgotPass
