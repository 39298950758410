import React, { FC } from "react";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import CommentCard, { CommentType,  } from "../../components/CommentCard/CommentCard";
import { CommentContent, IPagination } from "./SingleContent";

export interface SingleCommentListsProps {
  pagination?: IPagination;
  comments?: CommentType[];
  onSendReplyComment?: (e: React.FormEvent<HTMLFormElement>, threadOf: number | null | undefined) => void;
  replyCommentContent?: CommentContent;
  onChangeReply?: (key: string, value: string) => void;
  handleShowMoreComments?: () => void;
}

const SingleCommentLists: FC<SingleCommentListsProps> = ({
  pagination,
  comments, 
  onSendReplyComment,
  replyCommentContent,
  onChangeReply,
  handleShowMoreComments,
}) => {

  const renderCommentCard = () => {
    return comments && comments.length > 0 ? (
      comments.map((comment) => (
        <CommentCard
          commentReplyId={comment.id}
          key={comment.id}
          comment={comment}
          onSendReplyComment={onSendReplyComment}
          replyCommentContent={replyCommentContent}
          onChangeReply={onChangeReply}
        />
        ))
    ) : (
      <div className="">
        No comments
      </div>
    )
  }
  return (
    <ul className="nc-SingleCommentLists space-y-5">
      {renderCommentCard()}
      {
        pagination && pagination.total > pagination.page * pagination.pageSize &&
        <ButtonPrimary
          className="dark:bg-primary-700 w-full"
          onClick={handleShowMoreComments}
        >
          Show more comments
        </ButtonPrimary>
      }
    </ul>
  );
};

export default SingleCommentLists;
