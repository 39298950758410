import React, { useEffect} from 'react'
import SearchPageContent from './SearchPageContent'

interface SearchComponent {
    searchAuthor: boolean
    searchArticles: boolean
    searchCategories: boolean
    searchTags: boolean
    sizeSearch: number
    hasShowMeMore: boolean
    hasRelated: boolean
    imageBg?: string
    imageBgPosition: string
}
interface TrendingTopicComponent {
    title?: string
    description?: string
    hasShowMeMore: boolean
    sizeShowMeMore: number
}
interface EliteAuthorComponent {
    title?: string
    description?: string
    amountAuthors: number
}
interface NewLetterComponent {
    title?: string
    description?: string
    hasSendEmail: boolean
    placeHolder?: string
    bgImage?: string
    positionImageBg?: string
    badge?: BadgeComponent[]
}
interface BadgeComponent {
    content: string
}

export interface SearchPageSetting {
    searchComponent?: SearchComponent
    trendingTopicComponent?: TrendingTopicComponent
    eliteAuthorComponent?: EliteAuthorComponent
    newLetterComponent?: NewLetterComponent
}

const PageSearch = () => {

    useEffect(() => {
        document.title = 'Lotusa CMS | Search'
      }, [])
    
    return <SearchPageContent />
}

export default PageSearch
