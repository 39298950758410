/* eslint-disable react-hooks/exhaustive-deps */
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {
    ExclamationTriangleIcon,
    FolderIcon,
    LifebuoyIcon,
} from '@heroicons/react/24/outline'
import Image from '../../components/Image'
import Link from '../../components/Link'
import {
    createSearchParams,
    useNavigate,
} from 'react-router-dom'
import moment from 'moment'
import { getData } from 'templates/Ncmaz/utils/services'
import { defaultAvatar, defaultThumbnail, formatDataAuthors, handleHrefCardSearch, handleHrefCategorySearch } from 'templates/Ncmaz/utils/function'

// const categories = DEMO_CATEGORIES.filter((_, i) => i < 9)
// const posts = DEMO_POSTS.filter((_, i) => i < 5)
// const authors = DEMO_AUTHORS.filter((_, i) => i < 9)

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface Props {
    renderTrigger?: () => ReactNode
}
interface Authors {
    // define the object (singular)
    id: number
    firstName: string
    lastName: string
    displayName: string
    email: string
    gender: string
    avatar: string
    bgImage: string
    count: number
    href: string
    desc: string
    jobName: string
}
interface Posts {
    // define the object (singular)
    index: number
    id: number
    featuredImage: string
    title: string
    desc: string
    date: string
    href: string
    commentCount: number
    viewdCount: number
    readingTime: number
    bookmark: { count: number; isBookmarked: boolean }
    like: {
        count: number
        isLiked: boolean
    }
    authorId: number
    categoriesId: number[]
    postType: string
    author: Authors
}
interface Category {
    id: number
    name: string
    href: string
    thumbnail: string
    count: number
    color: string
    taxonomy: string
}
interface SearchResult {
    authors: Authors[]
    posts: Posts[]
    categories: Category[]
}
const SearchModal: FC<Props> = ({ renderTrigger }) => {
    const [rawQuery, setRawQuery] = useState('')
    // const [authors, setAuthors] = useState<Authors[]>([])
    // const [posts, setPosts] = useState<Posts[]>([])
    // const [categories, setCategories] = useState<Category[]>([])
    const [searchResult, setSearchResult] = useState<SearchResult>({
        authors: [],
        posts: [],
        categories: [],
    })
    const dataNumberQuery = '&pagination[start]=0&pagination[limit]=5'
    const query = encodeURIComponent(
        rawQuery.toLowerCase().replace(/^[#>]/, '')
    )
    const URL_ENUM = {
        AUTHOR: `/users?populate=deep,2${dataNumberQuery}&filters[username][$contains]=${query}&sort=count:desc`,
        NEWS: `/news?populate=deep,3&sort=updatedAt:desc${dataNumberQuery}&filters[title][$contains]=${query}`,
        CATEGORIES: `/category/items/info?populate=deep,2&sort=count:desc&filters[root_menu][slug]=news${dataNumberQuery}&filters[title][$contains]=${query}`,
    }
    const searchTimer: { current: ReturnType<typeof setTimeout> | null } =
        useRef(null)

    const getAuthors = () => {
        const authorsInfos =
            getData(URL_ENUM.AUTHOR)
                .then((res) => {
                    const authorsInfo = formatDataAuthors(res.data)
                    return authorsInfo
                })
                .catch((err) => {
                    console.log(err)
                })
        return authorsInfos
    }
    const getPosts = () => {
        const postsInfo = getData(URL_ENUM.NEWS)
            .then((res) => {
                const postsInfo = res.data.data.map((post: any) => {
                    const categoriesId =
                        post?.attributes?.categories?.items?.data.map(
                            (item: any) => item.id
                        )
                    const author = {
                        id: Number(post?.id),
                        firstName: String(
                            post?.attributes?.attributes?.firstName
                        ),
                        lastName: String(
                            post?.attributes?.attributes?.lastName
                        ),
                        displayName: String(
                            post?.attributes?.attributes?.displayName
                        ),
                        email: String(post?.attributes?.attributes?.email),
                        gender: String(post?.attributes?.attributes?.gender),
                        avatar: `${process.env.REACT_APP_URL_BE}${post?.attributes?.attributes?.avatar?.data?.attributes?.url}` || defaultAvatar,
                        bgImage: `${process.env.REACT_APP_URL_BE}${post?.attributes?.attributes?.bgImage?.data?.attributes?.url}` || defaultThumbnail,
                        count: Number(post?.attributes?.attributes?.count),
                        href: String(post?.attributes?.attributes?.href),
                        desc: String(post?.attributes?.attributes?.desc),
                        jobName: String(post?.attributes?.attributes?.jobName),
                    }
                    const postInfo = {
                        index: post?.id,
                        id: post?.id,
                        featuredImage: `${process.env.REACT_APP_URL_BE}${post?.attributes?.mainImage?.data?.attributes?.url}` || defaultThumbnail,
                        title: post?.attributes?.title,
                        desc: post?.attributes?.shortContent,
                        date: moment(`${post?.attributes?.publishedAt}`).format(
                            'LL'
                        ),
                        href: post?.attributes?.slug,
                        commentCount: post?.attributes?.totalComment,
                        viewdCount: post?.attributes?.totalView,
                        readingTime: 2,
                        bookmark: { count: 3007, isBookmarked: false },
                        like: {
                            count: post?.attributes?.totalLike,
                            isLiked: true,
                        },
                        authorId: post?.attributes?.author?.data?.id,
                        categoriesId: categoriesId,
                        postType: post?.attributes?.postType,
                        author: author,
                        viewLayout: post?.attributes?.viewLayout ? post?.attributes?.viewLayout : 'single'
                    }
                    return postInfo
                })
                return postsInfo
            })
            .catch((err) => {
                console.log(err)
            })
        return postsInfo
    }
    const getCategories = () => {
        const categoryInfos = getData(URL_ENUM.CATEGORIES)
            .then((res) => {
                const categoryInfos = res.data.data.map((category: any) => {
                    const categoryInfo = {
                        id: category?.id,
                        name: category?.attributes?.title,
                        href: handleHrefCategorySearch(category?.attributes?.layout, category?.attributes?.url, 'news'),
                        thumbnail: `${process.env.REACT_APP_URL_BE}${category?.attributes?.thumbnail?.data?.attributes?.url}` || defaultThumbnail,
                        count: category?.attributes?.count || 0,
                        color: category?.attributes?.bgColor,
                        taxonomy:
                            category?.attributes['root_menu']?.data?.attributes
                                ?.slug,
                    }
                    return categoryInfo
                })
                return categoryInfos
            })
            .catch((err) => {
                console.log(err)
            })
        return categoryInfos
    }
    useEffect(() => {
        const queryData = async () => {
            setLoading(true)
            if (rawQuery.startsWith('#')) {
                const postData: any = await getPosts()
                const cateData: any = await getCategories()
                if (postData && cateData) {
                    setSearchResult({
                        posts: postData,
                        categories: cateData,
                        authors: [],
                    })
                    setLoading(false)
                }
            } else if (rawQuery.startsWith('>')) {
                const authorData: any = await getAuthors()
                if (authorData) {
                    setSearchResult({
                        posts: [],
                        categories: [],
                        authors: authorData,
                    })
                    setLoading(false)
                }
            } else {
                const postData: any = await getPosts()
                const cateData: any = await getCategories()
                const authorData: any = await getAuthors()
                if (cateData && authorData && postData) {
                    setSearchResult({
                        posts: postData,
                        categories: cateData,
                        authors: authorData,
                    })
                    setLoading(false)
                }
            }
        }
        queryData()
    }, [rawQuery])
    const [open, setOpen] = useState(false)
    const handleCloseSearch = () => {
        setOpen(false)
        setRawQuery('')
    }
    const router = useNavigate()
    const [loading, setLoading] = useState(false)

    const displayLoadingBox = () => {
        return (
            <div role="status" className="loadingSpinner min-h-20">
                <svg
                    aria-hidden="true"
                    className="w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        )
    }
    const displayNoResultMatch = () => {
        return (
            <div className="py-14 px-6 text-center text-sm sm:px-14 min-h-20">
                <ExclamationTriangleIcon
                    className="mx-auto h-6 w-6 text-gray-400"
                    aria-hidden="true"
                />
                <p className="mt-4 font-semibold text-gray-900">
                    No results found
                </p>
                <p className="mt-2 text-gray-500">
                    We couldn’t find anything with that term. Please try again.
                </p>
            </div>
        )
    }
    const displayHelpBox = () => {
        return (
            <div className="py-14 px-6 text-center text-sm sm:px-14 min-h-20">
                <LifebuoyIcon
                    className="mx-auto h-6 w-6 text-gray-400"
                    aria-hidden="true"
                />
                <p className="mt-4 font-semibold text-gray-900">
                    Help with searching
                </p>
                <p className="mt-2 text-gray-500">
                    Use this tool to quickly search for users and projects
                    across our entire platform. You can also use the search
                    modifiers found in the footer below to limit the results to
                    just users or projects.
                </p>
            </div>
        )
    }
    const displayResultSearch = () => {
        return (
            <Combobox.Options
                static
                className="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2 min-h-20"
            >
                {searchResult.posts.length > 0 && (
                    <li>
                        <h2 className="text-xs font-semibold text-gray-900">
                            Posts
                        </h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700">
                            {searchResult.posts.map((post) => (
                                <Combobox.Option
                                    key={post.id}
                                    value={post}
                                    className={({ active }) =>
                                        classNames(
                                            'flex select-none items-center px-4 py-2',
                                            active && 'bg-indigo-600 text-white'
                                        )
                                    }
                                    onClick={() => setRawQuery('')}
                                >
                                    {({ active }) => (
                                        <>
                                            <MagnifyingGlassIcon
                                                className={classNames(
                                                    'h-6 w-6 flex-none',
                                                    active
                                                        ? 'text-white'
                                                        : 'text-gray-400'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="ml-3 flex-auto truncate">
                                                {post.title}
                                            </span>
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </ul>
                    </li>
                )}

                {searchResult.categories.length > 0 && (
                    <li>
                        <h2 className="text-xs font-semibold text-gray-900">
                            Categories
                        </h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700">
                            {searchResult.categories.map((project) => (
                                <Combobox.Option
                                    key={project.id}
                                    value={project}
                                    className={({ active }) =>
                                        classNames(
                                            'flex select-none items-center px-4 py-2',
                                            active && 'bg-indigo-600 text-white'
                                        )
                                    }
                                    onClick={() => setRawQuery('')}
                                >
                                    {({ active }) => (
                                        <>
                                            <FolderIcon
                                                className={classNames(
                                                    'h-6 w-6 flex-none',
                                                    active
                                                        ? 'text-white'
                                                        : 'text-gray-400'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="ml-3 flex-auto truncate">
                                                {project.name}
                                            </span>
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </ul>
                    </li>
                )}

                {searchResult.authors.length > 0 && (
                    <li>
                        <h2 className="text-xs font-semibold text-gray-900">
                            Authors
                        </h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700">
                            {searchResult.authors.map((user) => (
                                <Combobox.Option
                                    key={user.id}
                                    value={user}
                                    className={({ active }) =>
                                        classNames(
                                            'flex select-none items-center px-4 py-2',
                                            active && 'bg-indigo-600 text-white'
                                        )
                                    }
                                    onClick={() => setRawQuery('')}
                                >
                                    <Image
                                        src={user.avatar}
                                        alt="author"
                                        className="h-6 w-6 flex-none rounded-full"
                                        sizes="30px"
                                    />
                                    <span className="ml-3 flex-auto truncate">
                                        {user.displayName}
                                    </span>
                                </Combobox.Option>
                            ))}
                        </ul>
                    </li>
                )}
            </Combobox.Options>
        )
    }
    const displaySearchContent = () => {
        return (
            <>
                {(searchResult.categories.length > 0 ||
                    searchResult.authors.length > 0 ||
                    searchResult.posts.length > 0) &&
                    displayResultSearch()}

                {rawQuery === '?' && displayHelpBox()}

                {query !== '' &&
                    rawQuery !== '?' &&
                    searchResult.categories.length === 0 &&
                    searchResult.authors.length === 0 &&
                    searchResult.posts.length === 0 &&
                    displayNoResultMatch()}
            </>
        )
    }
    return (
        <>
            <div onClick={() => setOpen(true)} className="cursor-pointer">
                {renderTrigger ? (
                    renderTrigger()
                ) : (
                    <button className="flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center">
                        <svg
                            width={22}
                            height={22}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M22 22L20 20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                )}
            </div>

            <Transition.Root
                show={open}
                as={Fragment}
                // afterLeave={() => setRawQuery('a')}
                appear
            >
                <Dialog
                    as="div"
                    className="relative z-[99]"
                    onClose={handleCloseSearch}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/40 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-100"
                        >
                            <Dialog.Panel
                                className="block mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                                as="form"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    router({
                                        pathname: '/search',
                                        search: createSearchParams({
                                            match: query,
                                        }).toString(),
                                    })
                                    handleCloseSearch()
                                }}
                            >
                                <Combobox
                                    onChange={(item: any) => {
                                        let href = ''
                                        const { layout, viewLayout } = item
                                        if (layout) {
                                            href = handleHrefCategorySearch(layout, item.url, 'news')
                                        } else if (viewLayout) {
                                            href = handleHrefCardSearch(item.href, viewLayout)
                                        } else {
                                            href = item.href
                                        }

                                        router(href)
                                        handleCloseSearch()
                                    }}
                                    name="searchpallet"
                                >
                                    <div className="relative">
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <input
                                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                            placeholder="Search..."
                                            onChange={(event) => {
                                                if (searchTimer.current) {
                                                    clearTimeout(
                                                        searchTimer.current
                                                    )
                                                }
                                                searchTimer.current =
                                                    setTimeout(() => {
                                                        setRawQuery(
                                                            event.target.value
                                                        )
                                                    }, 300)
                                            }}
                                        />
                                    </div>
                                    {loading
                                        ? displayLoadingBox()
                                        : displaySearchContent()}

                                    <div className="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
                                        Type{' '}
                                        <kbd
                                            className={classNames(
                                                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                                rawQuery.startsWith('#')
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-gray-400 text-gray-900'
                                            )}
                                        >
                                            #
                                        </kbd>{' '}
                                        <span className="sm:hidden">
                                            for projects,
                                        </span>
                                        <span className="hidden sm:inline">
                                            to access projects,
                                        </span>
                                        <kbd
                                            className={classNames(
                                                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                                rawQuery.startsWith('>')
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-gray-400 text-gray-900'
                                            )}
                                        >
                                            &gt;
                                        </kbd>{' '}
                                        for users,{' '}
                                        <kbd
                                            className={classNames(
                                                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                                rawQuery === '?'
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-gray-400 text-gray-900'
                                            )}
                                        >
                                            ?
                                        </kbd>{' '}
                                        for help, or{' '}
                                        <Link
                                            href={'/search'}
                                            className="mx-1 flex h-5 px-1.5 items-center justify-center rounded border bg-white sm:mx-2 border-primary-6000 text-neutral-900"
                                            onClick={handleCloseSearch}
                                        >
                                            Go to search page
                                        </Link>{' '}
                                    </div>
                                </Combobox>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default SearchModal
