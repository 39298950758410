import { SectionMagazine1Props } from "../Sections/SectionMagazine1";
import {FC} from 'react'
import Component from "./page";


export interface HottestProps extends SectionMagazine1Props { }

const Hottest: FC<HottestProps> = ({
    posts,
    className = ''
}) => {    

    return (
        <div className="container relative ">
            <Component
                posts={posts}
                className={className}
            />
        </div>
    )
}

export default Hottest;