import React, {useState, ChangeEvent, useRef, forwardRef, useImperativeHandle, useEffect} from "react";
import {uploadImage} from "../../utils/services";

interface ImageUploadProps {
    onClick?: (file: any) => void;
    onClear?: boolean;
}

const InputImage = forwardRef((props: ImageUploadProps, ref: React.Ref<any>) => {
    const {onClick, onClear} = props;
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);

    const token = localStorage.getItem("expireToken")

    const fileInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileSize = file.size;

            // Kiểm tra định dạng file hợp lệ
            const allowedExtensions = ["png", "jpeg", "jpg", "gif", "svg", "tiff", "ico", "dvu"];
            const fileExtension = fileName.split(".").pop()?.toLowerCase();
            if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                setErrorMessage(
                    "Định dạng file không hợp lệ. Vui lòng chọn file PNG, JPEG, GIF, SVG, TIFF, ICO hoặc DVU."
                );
                return;
            }

            // Kiểm tra kích thước file hợp lệ
            const maxSize = 1024 * 1024 * 1024; // 1GB
            if (fileSize > maxSize) {
                setErrorMessage("Kích thước file quá lớn. Vui lòng chọn file nhỏ hơn 1GB.");
                return;
            }

            // Hiển thị preview của ảnh
            const reader = new FileReader();
            reader.onload = () => {
                setErrorMessage("");
                setSelectedFile(file);
                setPreviewUrl(reader.result as string);
                const imgElement = imageRef.current;
                if (imgElement) {
                    imgElement.style.display = "block";
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClear = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        setErrorMessage("");
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input value
        }
        const imgElement = imageRef.current;
        if (imgElement) {
            imgElement.style.display = "none";
        }
    };

    const handleSubmit = () => {
        uploadImage(token, selectedFile)
            .then(res => {
                setIsLoading(false)
                onClick && onClick({
                    id: res.data[0].id,
                    name: res.data[0].name,
                    url: res.data[0].url
                })
                setErrorMessage(
                    "Upload images successfully! Please close the popup to see the change!"
                )
            })
            .catch(err => {
                setIsLoading(false)
                onClick && onClick(err.message)
            })
    }

    React.useEffect(() => {
        if (onClear) {
            handleClear();
        }
    }, [onClear]);

    useEffect(() => {
        if (previewUrl) {
            imageRef.current?.setAttribute('src', previewUrl)
        }
    }, [previewUrl])

    const truncateFileName = (fileName: string, maxLength: number) => {
        if (fileName.length <= maxLength) {
            return fileName;
        } else {
            const truncatedName = fileName.substr(0, maxLength - 3) + "...";
            return truncatedName;
        }
    };

    useImperativeHandle(ref, () => ({
        clear: () => handleClear()
    }));

    return (
        <div className="flex flex-col content-center items-center">
            <div className="flex flex-col items-center mt-3">
                <label
                    htmlFor="upload-input"
                    className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent
        rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                    Choose File

                </label>
                <div>
                    {selectedFile && (
                        <p className="text-sm text-gray-600 mt-5">{truncateFileName(selectedFile.name, 70)}</p>
                    )}
                </div>
            </div>
            <input
                id="upload-input"
                type="file"
                accept="image/png, image/jpeg, image/gif, image/svg+xml, image/tiff, image/x-icon, image/vnd.dvu"
                className="hidden"
                ref={fileInputRef}
                onChange={fileInputChangeHandler}
                value={''}
            />
            {errorMessage && <p>{errorMessage}</p>}
            <img
                src={previewUrl || ''}
                alt="Preview"
                className="mt-5 w-6/12"
                ref={imageRef}
                style={{display: previewUrl ? "block" : "none"}}
            />
            {selectedFile &&
                <div
                    className={`px-4 py-2 rounded-md leading-5 mt-5 bg-green-500 text-sm font-medium text-white 
                    ${isLoading ? 'cursor-default select-none hover:bg-green-500' : 'cursor-pointer hover:bg-green-600'}`}
                    onClick={!isLoading ? () => {
                        handleSubmit()
                        setIsLoading(true)
                    } : () => {}}
                >
                    {isLoading
                        ? <>
                            <svg className="animate-spin h-5 w-5 border-x-4 rounded-3xl inline-flex items-center" viewBox="0 0 24 24">
                                -
                            </svg>
                        </>
                        : 'Submit'}
                </div>}
        </div>
    );
});

export default InputImage;
