import moment from 'moment'
import { putData } from './services'
import { CategoryLayout } from '../data/types'

export const defaultAvatar = '/default-avatar.jpg'
export const underConstructionImg = '/under-construction.png'
export const defaultThumbnail = '/default-thumbnail-xl.png'


export const formattedDataTag = (data: any) => {
    return data?.map((item: any) => ({
        id: item?.id,
        name: item?.title || item.name,
        taxonomy: 'tag',
        href: item?.href,
        count: item?.news?.length || item.count || 0,
    }))
}

export const formatPostType = (postType: string) => {
    const postTypes: any = {
        videoPost: 'video',
        audioPost: 'audio',
        galleryPost: 'gallery',
    }
    return postTypes[postType] || 'standard'
}

export const formatDataHomePage = (data: any) => {
    return data?.map((item: any, idx: number) => {
        const {
            id,
            mainImage,
            title,
            shortContent,
            updatedAt,
            slug,
            totalComment,
            totalView,
            totalLike,
            user,
            audioUrl,
            videoUrl,
            galleryImgs,
            categoryItems,
            postType,
            viewLayout,
        } = item

        const formattedCategories = categoryItems?.map((cate: any) => ({
            id: cate?.id,
            name: cate?.title,
            href: cate?.url,
            taxonomy: 'category',
            color: cate?.bgColor,
            thumbnail: cate?.thumbnail?.url
                ? process.env.REACT_APP_URL_BE + cate?.thumbnail?.url
                : defaultThumbnail,
            layout: cate?.layout,
        }))

        return {
            index: idx,
            id,
            featuredImage: mainImage?.url
                ? process.env.REACT_APP_URL_BE + mainImage?.url
                : defaultThumbnail,
            title,
            desc: shortContent,
            date: moment(updatedAt).format('MMM DD, YYYY'),
            href: slug,
            commentCount: totalComment || 0,
            viewdCount: totalView || 0,
            audioUrl,
            videoUrl,
            galleryImgs: galleryImgs?.map((item: any) => {
                return process.env.REACT_APP_URL_BE + item?.url + defaultThumbnail
            }),
            readingTime: totalView || 0,
            like: {
                count: totalLike || 0,
                isLike: false,
            },
            author: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                displayName: user?.username || item?.author?.displayName,
                avatar: user?.avatar?.url
                    ? process.env.REACT_APP_URL_BE + (user?.avatar?.url || '')
                    : defaultAvatar,
                bgImage: '',
                count: 0,
                desc: user?.desc !== null ? user?.desc : '',
                jobName: user?.jobName,
                href: '/author/' + user?.href,
                email: user?.email,
            },
            categories: formattedCategories,
            postType: formatPostType(postType),
            bookmark: {
                count: 0,
                isBookmarked: false,
            },
            viewLayout: viewLayout || 'Single',
        }
    })
}

export const formatDataNewDetail = (data: any) => {
    const {
        id,
        mainImage,
        title,
        shortContent,
        updatedAt,
        slug,
        totalComment,
        totalView,
        totalLike,
        categoryItems,
        postType,
        new_tags,
        viewLayout,
        categories,
    } = data

    const formattedCategories = categories?.items?.map((item: any) => ({
        id: item?.attributes?.id,
        name: item?.attributes?.title,
        href: item?.attributes?.url,
        taxonomy: 'category',
        color: item?.attributes?.bgColor,
        layout: item?.attributes?.layout,
        thumbnail: item?.attributes?.thumbnail?.url
            ? process.env.REACT_APP_URL_BE + item?.attributes?.thumbnail?.url
            : defaultThumbnail,
    }))

    const formattedTags = new_tags?.map((item: any) => ({
        id: item?.id,
        name: item?.title,
        href: item?.href,
        taxonomy: 'tag',
        color: item?.bgColor,
        layout: item?.layout,
        thumbnail: item?.thumbnail?.url
            ? process.env.REACT_APP_URL_BE + item?.thumbnail?.url
            : defaultThumbnail,
    }))

    const formattedGalleryImgs = data?.galleryImgs
        ? data?.galleryImgs?.map((item: any) => {
            return item?.url ? process.env.REACT_APP_URL_BE + item?.url : defaultThumbnail
        })
        : []

    return {
        id,
        featuredImage: process.env.REACT_APP_URL_BE + (mainImage?.url || ''),
        galleryImgs: formattedGalleryImgs,
        title,
        desc: shortContent,
        date: moment(updatedAt).format('MMM DD, YYYY'),
        href: slug,
        commentCount: totalComment || 0,
        viewdCount: totalView || 0,
        like: {
            count: totalLike || 0,
            isLike: false,
        },
        audioUrl: data?.audioUrl,
        videoUrl: data?.videoUrl,
        author: {
            id: data?.user?.id,
            firstName: data?.user?.firstName,
            lastName: data?.user?.lastName,
            displayName: data?.user?.username,
            avatar: data?.user?.avatar?.url
                ? process.env.REACT_APP_URL_BE + data?.user?.avatar?.url
                : defaultAvatar,
            bgImage: '',
            count: 0,
            desc: data?.user?.desc !== null ? data?.user?.desc : '',
            jobName: data?.user?.jobName,
            href: "/author/" + data?.user?.href,
            email: data?.user?.email,
        },
        categories: formattedCategories,
        postType: formatPostType(postType),
        bookmark: {
            count: 0,
            isBookmarked: false,
        },
        mainContent: data?.content,
        tags: formattedTags,
        readingTime: data?.readingTime,
        viewLayout: viewLayout || 'Single',
    }
}

export const formatDataWidget = (data: any) => {
    const findIndexByComponent = (component: string) =>
        Array.from(data).findIndex(
            (item: any) => item.__component === component
        )

    const getFormattedTags = () => {
        const tagIndex = findIndexByComponent('Tag')
        return data[tagIndex]?.items?.map((item: any) => ({
            id: item?.id,
            name: item?.title,
            href: item?.href,
            count: item?.news?.length || 0,
        }))
    }

    const getFormattedAuthors = () => {
        const authorIndex = findIndexByComponent('Author')
        return data[authorIndex]?.items?.map((item: any) => ({
            id: item?.id,
            firstName: item?.firstName,
            lastName: item?.lastName,
            displayName: item?.username,
            avatar: item?.avatar?.url
                ? process.env.REACT_APP_URL_BE + item?.avatar?.url
                : defaultAvatar,
            bgImage: item?.avatar?.url
                ? process.env.REACT_APP_URL_BE + item?.bgImage?.url
                : defaultThumbnail,
            count: 0,
            desc: item?.desc || '',
            jobName: item?.jobName,
            href: "/author/" + item?.href,
            email: item?.email,
        }))
    }

    const getFormattedCategories = () => {
        const categoryIndex = findIndexByComponent('TrendingTopic')
        return data[categoryIndex]?.items?.map((item: any) => ({
            id: item?.id,
            name: item?.title,
            href: item?.url,
            taxonomy: 'category',
            color: item?.bgColor,
            count: item?.news?.length,
            layout: item?.layout,
            thumbnail: item?.thumbnail?.url
                ? process.env.REACT_APP_URL_BE + item?.thumbnail?.url
                : defaultThumbnail,
        }))
    }

    const getFormattedPosts = () => {
        const postIndex = findIndexByComponent('PopularPost')
        return data[postIndex]?.items?.map((item: any, idx: number) => ({
            index: idx,
            id: item?.id,
            featuredImage: item?.mainImage?.url ? process.env.REACT_APP_URL_BE + item?.mainImage?.url : defaultThumbnail,
            title: item?.title,
            desc: item?.shortContent,
            date: moment(item?.updatedAt).format('MMM DD, YYYY'),
            audioUrl: item?.audioUrl,
            videoUrl: item?.videoUrl,
            galleryImgs: item?.galleryImgs?.map((img: any) => {
                return img.url ? `${process.env.REACT_APP_URL_BE}${img.url}` : defaultThumbnail
            }),
            href: item?.slug,
            postType: formatPostType(item?.postType),
            commentCount: item?.totalComment || 0,
            viewdCount: item?.totalView || 0,
            readingTime: item?.totalView || 0,
            viewLayout: item?.viewLayout || 'Single',
            author: {
                id: item?.user?.id,
                firstName: item?.user?.firstName,
                lastName: item?.user?.lastName,
                displayName: item?.user?.username,
                avatar: item?.user?.avatar?.url ?
                    process.env.REACT_APP_URL_BE +
                    item?.user?.avatar?.url : defaultAvatar,
                bgImage: '',
                count: 0,
                desc: item?.user?.desc !== null ? item?.user?.desc : '',
                jobName: item?.user?.jobName,
                href: "/author/" + item?.user?.href,
                email: item?.user?.email,
            },
        }))
    }

    return {
        tags: getFormattedTags(),
        authors: getFormattedAuthors(),
        categories: getFormattedCategories(),
        posts: getFormattedPosts(),
    }
}

export const formatDataTabs = (data: any) => {
    return data?.map((item: any) => `${item?.attributes?.title}`)
}

export const formatDataAuthor = (data: any) => {
    const socials = data?.socialNetwork?.map((item: any) => ({
        id: item.id,
        name: item.name,
        href: item.href,
        icon: item.socialIcon,
    }))
    return {
        id: data?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        displayName: data?.username,
        avatar: data?.avatar?.url
            ? process.env.REACT_APP_URL_BE + data?.avatar?.url
            : defaultAvatar,
        bgImage: data?.bgImage?.url
            ? process.env.REACT_APP_URL_BE + data?.bgImage?.url
            : defaultThumbnail,
        count: 0,
        desc: data?.desc !== null ? data?.desc : '',
        jobName: data?.jobName,
        href: "/author/" + data?.href,
        email: data?.email,
        personalWebsite: data?.personalWebsite,
        socialNetwork: socials,
    }
}

export const formatDataAuthors = (data: any) => {
    return data?.map((item: any) => ({
        id: item?.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        displayName: item?.username || item.displayName,
        avatar: item?.avatar?.url
            ? process.env.REACT_APP_URL_BE + item?.avatar?.url
            : defaultAvatar,
        bgImage: item?.bgImage?.url
            ? process.env.REACT_APP_URL_BE + item?.bgImage?.url
            : defaultThumbnail,
        count: item?.news?.length || item.count,
        desc: item?.desc,
        jobName: item?.jobName,
        href: "/author/" + item?.href,
        email: item?.email
    }))
}

export interface HandleLikeArg {
    url?: string
    isLike: boolean
    setIsLike: any
    likeCount: number
    setLikeCount: any
    postId: number | string
}

export const handleLike = ({
    url = 'news',
    isLike,
    setIsLike,
    likeCount,
    setLikeCount,
    postId,
}: HandleLikeArg) => {
    setIsLike(!isLike)
    putData(`/${url}/${postId}`, {
        totalLike: !isLike ? likeCount + 1 : likeCount - 1,
    }).then((res) => {
        setLikeCount(res.data.data.attributes.totalLike)
    })
}

export type KindCategory = 'blog' | 'news' | 'product'

export const handleHrefCategory = (layout: CategoryLayout, href: string, kindCategory: KindCategory) => {
    const { pathname, origin } = window.location

    let prefix = ''
    switch (layout) {
        case 'video':
            prefix = '/category-video'
            break
        case 'audio':
            prefix = '/category-audio'
            break
        default:
            prefix = '/category'
            break
    }

    const basePath = pathname !== '/' ? `${origin}/` : ''
    const lastHref = basePath + kindCategory + prefix + href
    return lastHref
}

export const handleHrefCategorySearch = (layout: CategoryLayout, href: string, kindCategory: KindCategory) => {

    let prefix = ''
    switch (layout) {
        case 'video':
            prefix = '/category-video'
            break
        case 'audio':
            prefix = '/category-audio'
            break
        default:
            prefix = '/category'
            break
    }

    const lastHref = kindCategory + prefix + href
    return lastHref
}


export const formatDataCategoryDetailPage = (data: any) => {
    const { trendingTopic, categoryInfo, topAuthors } = data

    const formattedCategoryInfo = {
        id: categoryInfo.id,
        name: categoryInfo?.title,
        count: categoryInfo?.news?.length,
        thumbnail: categoryInfo?.thumbnail?.url
            ? process.env.REACT_APP_URL_BE + categoryInfo?.thumbnail?.url
            : defaultThumbnail,
        layout: categoryInfo?.layout,
    };

    const formattedPosts = categoryInfo?.news?.map((item: any, idx: number) => ({
        index: idx,
        id: item?.id,
        featuredImage: item?.mainImage?.url ? process.env.REACT_APP_URL_BE + item?.mainImage?.url : defaultThumbnail,
        title: item?.title,
        desc: item?.shortContent,
        date: moment(item?.updatedAt).format('MMM DD, YYYY'),
        href: item?.slug,
        audioUrl: item?.audioUrl,
        videoUrl: item?.videoUrl,
        galleryImgs: item?.galleryImgs?.map((img: any) => {
            return img?.url ? process.env.REACT_APP_URL_BE + img?.url + '' : defaultThumbnail
        }),
        commentCount: item?.totalComment || 0,
        viewdCount: item?.totalView || 0,
        readingTime: item?.totalView || 0,
        like: {
            count: item?.totalLike || 0,
            isLike: false,
        },
        author: {
            id: item?.user?.id,
            firstName: item?.user?.firstName,
            lastName: item?.user?.lastName || '',
            displayName: item?.user?.username,
            avatar: item?.user?.avatar?.url ? process.env.REACT_APP_URL_BE + item?.user?.avatar?.url : defaultAvatar,
            count: 0,
            desc: item?.user?.desc !== null ? item?.user?.desc : '',
            href: "/author/" + item?.user?.href,
            email: item?.user?.email,
        },
        categories: item?.categoryItems?.map((cate: any) => ({
            id: cate?.id,
            name: cate?.title,
            href: cate?.url,
            taxonomy: 'category',
            color: cate?.bgColor,
            thumbnail: cate?.thumbnail?.url ? process.env.REACT_APP_URL_BE + cate?.thumbnail?.url : defaultThumbnail,
            layout: cate?.layout,
        })),
        postType: formatPostType(item?.postType),
        bookmark: {
            count: 0,
            isBookmarked: false,
        },
        viewLayout: item?.viewLayout || 'Single'
    }));

    const formatTopAuthors = topAuthors?.map((item: any) => ({
        id: item?.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        displayName: item?.username || item.displayName,
        avatar: item?.avatar?.url
            ? process.env.REACT_APP_URL_BE + item?.avatar?.url
            : defaultAvatar,
        bgImage: item?.bgImage?.url
            ? process.env.REACT_APP_URL_BE + item?.bgImage?.url
            : defaultThumbnail,
        count: item?.news?.length || item.count,
        desc: item?.desc,
        jobName: item?.jobName,
        href: "/author/" + item?.href,
        email: item?.email
    }))

    const formatTrendingTopic = formatDataCategories(trendingTopic)
    return {
        topAuthors: formatTopAuthors,
        categoryInfo: formattedCategoryInfo,
        trendingTopic: formatTrendingTopic,
        posts: formattedPosts,
    }
}

export const handleHrefCard = (
    href: string,
    viewLayout:
        | 'Single'
        | 'Single-2'
        | 'Single-3'
        | 'Single-4'
        | 'Single-5'
        | 'Single Audio'
        | 'Single Video'
        | 'Single Gallery'
) => {
    const { pathname, origin } = window.location

    const viewLayouts: Record<string, string> = {
        Single: 'single',
        'Single-2': 'single-2',
        'Single-3': 'single-3',
        'Single-4': 'single-4',
        'Single-5': 'single-5',
        'Single Audio': 'single-audio',
        'Single Video': 'single-video',
        'Single Gallery': 'single-gallery',
    }

    const basePath = pathname !== '/' ? `${origin}/` : ''
    return `${basePath}news/${viewLayouts[viewLayout] ? viewLayouts[viewLayout] : 'single'
        }/${href}`
}

export const handleHrefCardSearch = (
    href: string,
    viewLayout:
        | 'Single'
        | 'Single-2'
        | 'Single-3'
        | 'Single-4'
        | 'Single-5'
        | 'Single Audio'
        | 'Single Video'
        | 'Single Gallery'
) => {
    const viewLayouts: Record<string, string> = {
        Single: 'single',
        'Single-2': 'single-2',
        'Single-3': 'single-3',
        'Single-4': 'single-4',
        'Single-5': 'single-5',
        'Single Audio': 'single-audio',
        'Single Video': 'single-video',
        'Single Gallery': 'single-gallery',
    }

    return `news/${viewLayouts[viewLayout] ? viewLayouts[viewLayout] : 'single'
        }/${href}`
}

export const handleHrefAuthor = (href: string) => {
    const { pathname, origin } = window.location

    const basePath = pathname !== '/' ? `${origin}/` : ''
    return `${basePath}author/${href}`
}

export const convertSecondsToTime = (seconds: number) => {
    var hours = Math.floor(seconds / 3600)
    var minutes = Math.floor((seconds % 3600) / 60)
    var remainingSeconds = Math.floor(seconds % 60)

    var timeString = ''

    if (hours > 0) {
        if (hours < 10) {
            timeString += '0'
        }
        timeString += hours + 'h '
    }

    if (minutes < 10) {
        timeString += '0'
    }
    timeString += minutes + 'm '

    if (remainingSeconds < 10) {
        timeString += '0'
    }
    timeString += remainingSeconds + 's'

    return timeString
}

export const formatNewDataArr = (data: any) => {
    return data?.map((item: any) => formatDataNewDetail(item))
}

export const formatDataCategories = (data: any) => {
    return data?.map((item: any) => ({
        id: item?.id,
        name: item?.title || item.name,
        href: item?.url || item.href,
        taxonomy: 'category',
        color: item?.bgColor,
        count: item?.news?.length,
        layout: item?.layout,
        thumbnail: item?.thumbnail?.url
            ? process.env.REACT_APP_URL_BE + item?.thumbnail?.url
            : defaultThumbnail,
    }))
}

export const formatDataTags = (data: any) => {
    return data?.map((item: any) => ({
        id: item?.id,
        name: item?.title,
        taxonomy: 'tag',
        href: item?.href,
        count: item?.news?.length,
    }))
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}
export const formatDataPosts = (data: any) => {
    return data?.map((item: any, idx: number) => ({
        index: idx,
        id: item?.id,
        featuredImage: process.env.REACT_APP_URL_BE + item?.mainImage?.url,
        title: item?.title,
        desc: item?.shortContent,
        date: moment(item?.updatedAt).format('MMM DD, YYYY'),
        href: item?.slug,
        audioUrl: item?.audioUrl,
        videoUrl: item?.videoUrl,
        galleryImgs: item?.galleryImgs?.map((img: any) => {
            return img?.url ? process.env.REACT_APP_URL_BE + img?.url + '' : defaultThumbnail
        }),
        commentCount: item?.totalComment || 0,
        viewdCount: item?.totalView || 0,
        readingTime: item?.totalView || 0,
        like: {
            count: item?.totalLike || 0,
            isLike: false,
        },
        author: {
            id: item?.user?.id,
            firstName: item?.user?.firstName,
            lastName: item?.user?.lastName || '',
            displayName: item?.user?.username,
            avatar: item?.user?.avatar?.url
                ? process.env.REACT_APP_URL_BE + item?.user?.avatar?.url
                : defaultAvatar,
            count: 0,
            desc: item?.user?.desc !== null ? item?.user?.desc : '',
            href: "/author/" + item?.user?.href,
            email: item?.user?.email,
        },
        categories: item?.categoryItems?.map((cate: any) => ({
            id: cate?.id,
            name: cate?.title,
            href: cate?.url,
            taxonomy: 'category',
            color: cate?.bgColor,
            thumbnail: cate?.thumbnail?.url ? process.env.REACT_APP_URL_BE + cate?.thumbnail?.url : defaultThumbnail,
            layout: cate?.layout,
        })),
        postType: formatPostType(item?.postType),
        bookmark: {
            count: 0,
            isBookmarked: false,
        },
    }))
}

export const handleHrefTag = (href: string, kindCategory: KindCategory) => {
    const { pathname, origin } = window.location

    const basePath = pathname !== '/' ? `${origin}/` : ''
    return `${basePath}${kindCategory}/tag/${href}`
}

export const formatDataTagDetailPage = (data: any) => {
    const { trendingTopic, tagInfo, topAuthors } = data

    const formattedTagInfo = {
        id: tagInfo.id,
        name: tagInfo?.title,
        count: tagInfo?.news?.length,
        href: tagInfo?.href,
    }

    const formattedPosts = tagInfo?.news?.map((item: any, idx: number) => ({
        index: idx,
        id: item?.id,
        featuredImage: process.env.REACT_APP_URL_BE + item?.mainImage?.url,
        title: item?.title,
        desc: item?.shortContent,
        date: moment(item?.updatedAt).format('MMM DD, YYYY'),
        href: item?.slug,
        audioUrl: item?.audioUrl,
        videoUrl: item?.videoUrl,
        galleryImgs: item?.galleryImgs?.map((img: any) => {
            return img?.url ? process.env.REACT_APP_URL_BE + img?.url + '' : defaultThumbnail
        }), commentCount: item?.totalComment || 0,
        viewdCount: item?.totalView || 0,
        readingTime: item?.totalView || 0,
        like: {
            count: item?.totalLike || 0,
            isLike: false,
        },
        author: {
            id: item?.user?.id,
            firstName: item?.user?.firstName,
            lastName: item?.user?.lastName || '',
            displayName: item?.user?.username,
            avatar: item?.user?.avatar?.url
                ? process.env.REACT_APP_URL_BE + item?.user?.avatar?.url
                : defaultAvatar,
            count: 0,
            desc: item?.user?.desc !== null ? item?.user?.desc : '',
            href: "/author/" + item?.user?.href,
            email: item?.user?.email,
        },
        categories: item?.categoryItems?.map((cate: any) => ({
            id: cate?.id,
            name: cate?.title,
            href: cate?.url,
            taxonomy: 'category',
            color: cate?.bgColor,
            thumbnail: cate?.thumbnail?.url
                ? process.env.REACT_APP_URL_BE + cate?.thumbnail?.url
                : defaultThumbnail,
            layout: cate?.layout,
        })),
        postType: formatPostType(item?.postType),
        bookmark: {
            count: 0,
            isBookmarked: false,
        },
        viewLayout: item?.viewLayout || 'Single',
    }))

    const formatTopAuthors = topAuthors?.map((item: any) => ({
        id: item?.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        displayName: item?.username || item.displayName,
        avatar: item?.avatar?.url
            ? process.env.REACT_APP_URL_BE + item?.avatar?.url
            : defaultAvatar,
        bgImage: item?.bgImage?.url
            ? process.env.REACT_APP_URL_BE + item?.bgImage?.url
            : defaultThumbnail,
        count: item?.news?.length || item.count,
        desc: item?.desc,
        jobName: item?.jobName,
        href: "/author/" + item?.href,
        email: item?.email
    }))

    const formatTrendingTopic = formatDataCategories(trendingTopic)
    return {
        topAuthors: formatTopAuthors,
        tagInfo: formattedTagInfo,
        trendingTopic: formatTrendingTopic,
        posts: formattedPosts,
    };
};

export const generateUID = () => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 16;
    let uid = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uid += characters[randomIndex];
    }

    return uid;
}

