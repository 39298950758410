import { FC } from "react"
import { PostDataType } from "templates/Ncmaz/data/types";
import Component from "./page";

export interface AllTypePost {
  posts: PostDataType[];
  className?: string;
  gapClassName?: string;
  heading?: string;
  desc?: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const AllTypePost: FC<AllTypePost> = ({
  posts,
  className = "pt-16 lg:pt-24",
  gapClassName = "gap-6 md:gap-8",
  heading = "",
  desc
}) => {
  
  return (
    <div className="container relative">
      <Component
        posts={posts}
        className={className}
        gapClassName={gapClassName}
        heading={heading}
        desc={desc}
      />
    </div>
  );
};

export default AllTypePost;