import React from 'react';
import s from  './index.module.css'; // Import CSS file

const LoadingScreen = () => {
  return (
    <div className={s['loading-indicator-container']}>
      <div className={s['loading-indicator-circle']}></div>
    </div>
  );
};

export default LoadingScreen;
