import React, { useEffect, useRef, useState } from 'react'
import { PostAuthorType, PostDataType, TaxonomyType } from '../../../data/types'
import ButtonPrimary from '../../../components/Button/ButtonPrimary'
import Nav from '../../../components/Nav/Nav'
import NavItem from '../../../components/NavItem/NavItem'
import ArchiveFilterListBox from '../../../components/ArchiveFilterListBox/ArchiveFilterListBox'
import Input from '../../../components/Input/Input'
import SectionSubscribe2 from '../../../components/SectionSubscribe2/SectionSubscribe2'
import NcImage from '../../../components/NcImage/NcImage'
import NcLink from '../../../components/NcLink/NcLink'
import SectionSliderNewAuthors from '../../../components/SectionSliderNewAthors/SectionSliderNewAuthors'
import ButtonSecondary from '../../../components/Button/ButtonSecondary'
import SectionGridCategoryBox from '../../../components/SectionGridCategoryBox/SectionGridCategoryBox'
import BackgroundSection from '../../../components/BackgroundSection/BackgroundSection'
import Card11 from '../../../components/Card11/Card11'
import ButtonCircle from '../../../components/Button/ButtonCircle'
import CardCategory2 from '../../../components/CardCategory2/CardCategory2'
import Tag from '../../../components/Tag/Tag'
import CardAuthorBox2 from '../../../components/CardAuthorBox2/CardAuthorBox2'
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import Loading from 'templates/Ncmaz/components/Button/Loading'
import { useSearchParams } from 'react-router-dom'
import NoResultPage from 'templates/Ncmaz/components/NoResult/page'
import { getData } from 'templates/Ncmaz/utils/services'

// const posts: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 12)
// const cats = DEMO_CATEGORIES.filter((_, i) => i < 15)
// const tags = DEMO_CATEGORIES.filter((_, i) => i < 32)
// const authors = DEMO_AUTHORS.filter((_, i) => i < 12)

const FILTERS = [
    { name: 'Most Recent' },
    { name: 'Curated by Admin' },
    { name: 'Most Appreciated' },
    { name: 'Most Discussed' },
    { name: 'Most Viewed' },
]

export interface TrendingTopic {
    page: number
    size: number
    totalPage: number
    topics: TaxonomyType[]
}
interface PageSetting {
    page: number
    pageSize: number
    totalPage: number
    totalMatch?: number
}

export const displayLoading = () => {
    return (
        <div className="flex w-full h-full items-center justify-center min-h-20">
            <Loading />
        </div>
    )
}

const SearchPageContent = () => {
    const [posts, setPosts] = useState<PostDataType[]>([])
    const [authors, setAuthors] = useState<PostAuthorType[]>([])
    const [cats, setCats] = useState<TaxonomyType[]>([])
    const [tags, setTags] = useState<TaxonomyType[]>([])
    const [trendingTopic, setTrendingTopic] = useState<TrendingTopic>({
        page: 0,
        size: 3,
        totalPage: 1,
        topics: [],
    })
    const [authorsElite, setAuthorsElite] = useState<PostAuthorType[]>([])
    const [pageSetting, setPageSetting] = useState<PageSetting>({
        page: 0,
        pageSize: 2,
        totalPage: 4,
        totalMatch: 0,
    })
    const [loading, setLoading] = useState<string | boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const textSerch = searchParams.get('match')

    const searchTimer: { current: ReturnType<typeof setTimeout> | null } =
        useRef(null)

    const [TABS, setTABS] = useState([
        'Articles',
        'Categories',
        'Tags',
        'Authors',
    ])
    const [tabActive, setTabActive] = useState(TABS[0])

    console.log(posts)

    useEffect(() => {
        const textSearch = searchParams.get('match')
            ? searchParams.get('match')
            : ''
        setLoading('search')
        if (tabActive === 'Articles') {
            getData(
                `/searchNews?match=${textSearch}&page=${pageSetting.page}&size=${pageSetting.pageSize}`
            )
                .then((res) => {
                    const postsList = res?.data?.data.map(
                        (post: PostDataType) => {
                            post.author.avatar = post?.author?.avatar
                                ? `${process.env.REACT_APP_URL_BE}${post?.author?.avatar}`
                                : '../../../../../../public/default-avatar.jpg'
                            post.categories[0].thumbnail = post?.categories[0]
                                ?.thumbnail
                                ? `${process.env.REACT_APP_URL_BE}${post?.categories[0]?.thumbnail}`
                                : '../../../../../../public/default-thumbnail-md.jpg'
                            post.featuredImage = post?.featuredImage
                                ? `${process.env.REACT_APP_URL_BE}${post?.featuredImage}`
                                : '../../../../../../public/default-thumbnail-md.jpg'
                            return post
                        }
                    )
                    if (pageSetting.page === 0) {
                        setPosts(postsList)
                        setPageSetting((preState) => ({
                            ...preState,
                            totalPage: res?.data?.totalPage,
                            totalMatch: res?.data?.totalMatch,
                        }))
                    } else {
                        setPosts((preState) => [...preState, ...postsList])
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)

                    setLoading(false)
                })
        }
        if (tabActive === 'Authors') {
            getData(
                `/searchAuthors?match=${textSearch}&page=${pageSetting.page}&size=${pageSetting.pageSize}`
            )
                .then((res) => {
                    const authorsList = res?.data?.data.map(
                        (author: PostAuthorType) => {
                            author.avatar = author?.avatar
                                ? `${process.env.REACT_APP_URL_BE}${author?.avatar}`
                                : '../../../../../../public/default-avatar.jpg'
                            author.bgImage = author?.bgImage
                                ? `${process.env.REACT_APP_URL_BE}${author?.bgImage}`
                                : '../../../../../../public/default-thumbnail-md.jpg'
                            return author
                        }
                    )
                    if (pageSetting.page === 0) {
                        setAuthors(authorsList)
                        setPageSetting((preState) => ({
                            ...preState,
                            totalPage: res?.data?.totalPage,
                            totalMatch: res?.data?.totalMatch,
                        }))
                    } else {
                        setAuthors((preState) => [...preState, ...authorsList])
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)

                    setLoading(false)
                })
        }
        if (tabActive === 'Categories') {
            getData(
                `/category/items/searchCategories?match=${textSearch}&taxonomy=${tabActive}&page=${pageSetting.page}&size=${pageSetting.pageSize}`
            )
                .then((res) => {
                    const catsList = res?.data?.data.map(
                        (cat: TaxonomyType) => {
                            cat.thumbnail = cat?.thumbnail
                                ? `${process.env.REACT_APP_URL_BE}${cat?.thumbnail}`
                                : '../../../../../../public/default-thumbnail-md.jpg'
                            return cat
                        }
                    )

                    if (pageSetting.page === 0) {
                        setCats(catsList)
                        setPageSetting((preState) => ({
                            ...preState,
                            totalPage: res?.data?.totalPage,
                            totalMatch: res?.data?.totalMatch,
                        }))
                    } else {
                        setCats((preState) => [...preState, ...catsList])
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)

                    setLoading(false)
                })
        }
        if (tabActive === 'Tags') {
            getData(
                `/category/items/searchCategories?match=${textSearch}&taxonomy=${tabActive}&page=${pageSetting.page}&size=${pageSetting.pageSize}`
            )
                .then((res) => {
                    const catsList = res?.data?.data.map(
                        (tag: TaxonomyType) => {
                            tag.thumbnail = tag?.thumbnail
                                ? `${process.env.REACT_APP_URL_BE}${tag?.thumbnail}`
                                : '../../../../../../public/default-thumbnail-md.jpg'
                            return tag
                        }
                    )
                    if (pageSetting.page === 0) {
                        setTags(catsList)
                        setPageSetting((preState) => ({
                            ...preState,
                            totalPage: res?.data?.totalPage,
                            totalMatch: res?.data?.totalMatch,
                        }))
                    } else {
                        setTags((preState) => [...preState, ...catsList])
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)

                    setLoading(false)
                })
        }
    }, [tabActive, pageSetting.page, pageSetting.pageSize, searchParams])

    useEffect(() => {
        setLoading('trendingTopic')
        getData(
            `/trendingTopics?page=${trendingTopic.page}&size=${trendingTopic.size}`
        )
            .then((res) => {
                const topics = res?.data?.data.map((cat: TaxonomyType) => {
                    cat.thumbnail = cat?.thumbnail
                        ? `${process.env.REACT_APP_URL_BE}${cat?.thumbnail}`
                        : '../../../../../../public/default-thumbnail-md.jpg'
                    return cat
                })

                if (trendingTopic.page === 0) {
                    setTrendingTopic((preState) => ({
                        ...preState,
                        topics: res?.data?.data,
                        totalPage: res?.data?.totalPage,
                    }))
                } else {
                    setTrendingTopic((preState) => ({
                        ...preState,
                        topics: [...preState.topics, ...topics],
                    }))
                }

                setLoading(false)
            })
            .catch((err) => {
                console.log(err)

                setLoading(false)
            })
    }, [trendingTopic.page, trendingTopic.size])
    useEffect(() => {
        setLoading('author')
        axios
            .get(`${process.env.REACT_APP_API_URL}/authorsElite?page=0&size=10`)
            .then((res) => {
                const authorsList = res?.data?.data.map(
                    (author: PostAuthorType) => {
                        author.avatar = author?.avatar
                            ? `${process.env.REACT_APP_URL_BE}${author?.avatar}`
                            : '../../../../../../public/default-thumbnail-md.jpg'
                        author.bgImage = author?.bgImage
                            ? `${process.env.REACT_APP_URL_BE}${author?.bgImage}`
                            : '../../../../../../public/default-thumbnail-md.jpg'
                        return author
                    }
                )
                setAuthorsElite(authorsList)
            })
            .catch((err) => {
                console.log(err)

                setLoading(false)
            })
    }, [])

    const handleMoreSearch = () => {
        if (pageSetting.page < pageSetting.totalPage - 1) {
            setPageSetting((preState) => ({
                ...preState,
                page: preState.page + 1,
            }))
        }
    }
    const handleMoreTopics = () => {
        console.log(trendingTopic.totalPage)
        if (trendingTopic.page < trendingTopic.totalPage - 1) {
            setTrendingTopic((preState) => ({
                ...preState,
                page: preState.page + 1,
            }))
        }
    }
    const handleClickTab = (item: string) => {
        if (item === tabActive) {
            return
        }
        setPageSetting((preState) => ({
            ...preState,
            page: 0,
        }))
        setTabActive(item)
    }
    const displaySearchContent = () => {
        if (
            (posts.length === 0 && tabActive === 'Articles') ||
            (cats.length === 0 && tabActive === 'Categories') ||
            (tags.length === 0 && tabActive === 'Tags') ||
            (authors.length === 0 && tabActive === 'Authors')
        ) {
            return (
                <NoResultPage
                    message={`We'are not getting any resutl of ${tabActive} with your search ${searchParams.get(
                        'match'
                    )}`}
                />
            )
        }
        switch (tabActive) {
            case 'Articles':
                return (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
                        {posts.map((post) => (
                            <Card11 key={post.id} post={post} />
                        ))}
                    </div>
                )
            case 'Categories':
                return (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">
                        {cats.map((cat) => (
                            <CardCategory2 key={cat.id} taxonomy={cat} />
                        ))}
                    </div>
                )
            case 'Tags':
                return (
                    <div className="flex flex-wrap mt-12 ">
                        {tags.map((tag) => (
                            <Tag className="mb-3 mr-3" key={tag.id} tag={tag} />
                        ))}
                    </div>
                )
            case 'Authors':
                return (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">
                        {authors.map((author) => (
                            <CardAuthorBox2 key={author.id} author={author} />
                        ))}
                    </div>
                )

            default:
                return displayLoading()
        }
    }

    return (
        <div className={`nc-PageSearch`}>
            {/* HEADER */}
            <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
                <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-9 lg:aspect-h-5 overflow-hidden z-0">
                    <NcImage
                        alt="search"
                        fill
                        containerClassName="absolute inset-0"
                        src="https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                        className="object-cover w-full h-full"
                        sizes="(max-width: 1280px) 100vw, 1536px"
                    />
                </div>
                {/* CONTENT */}
                <div className="relative container -mt-20 lg:-mt-48">
                    <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
                        <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
                            {/* <h2 className="text-2xl sm:text-4xl font-semibold">
                                {s}
                            </h2> */}
                            {searchParams.get('match') && (
                                <span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                                    We found{' '}
                                    <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                                        {pageSetting.totalMatch}
                                    </strong>{' '}
                                    results for{' '}
                                    <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                                        {searchParams.get('match')
                                            ? searchParams.get('match')
                                            : ''}
                                    </strong>
                                </span>
                            )}

                            <form
                                className="relative w-full mt-8 sm:mt-11 text-left"
                                method="post"
                            >
                                <label
                                    htmlFor="search-input"
                                    className="text-neutral-500 dark:text-neutral-300"
                                >
                                    <span className="sr-only">
                                        Search all icons
                                    </span>
                                    <Input
                                        id="search-input"
                                        type="search"
                                        placeholder="Type and press enter"
                                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                        defaultValue={
                                            textSerch ? textSerch : ''
                                        }
                                        onChange={(e) => {
                                            if (searchTimer.current) {
                                                clearTimeout(
                                                    searchTimer.current
                                                )
                                            }
                                            searchTimer.current = setTimeout(
                                                () => {
                                                    setSearchParams({
                                                        match: e.target.value,
                                                    })
                                                    setPageSetting(
                                                        (preState) => ({
                                                            ...preState,
                                                            page: 0,
                                                        })
                                                    )
                                                },
                                                1000
                                            )
                                        }}
                                    />
                                    <ButtonCircle
                                        className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                        size=" w-11 h-11"
                                        type="submit"
                                    >
                                        <ArrowSmallRightIcon className="w-6 h-6" />
                                    </ButtonCircle>
                                    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                                        <svg
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </label>
                            </form>
                            <div className="w-full text-sm text-left mt-4 text-neutral-500 dark:text-neutral-300">
                                <div className="inline-block space-x-1.5 sm:space-x-2.5">
                                    <span className="">Related:</span>
                                    <NcLink
                                        className="inline-block font-normal"
                                        href="/search"
                                    >
                                        Design
                                    </NcLink>
                                    <NcLink
                                        className="inline-block font-normal"
                                        href="/search"
                                    >
                                        Photo
                                    </NcLink>
                                    <NcLink
                                        className="inline-block font-normal"
                                        href="/search"
                                    >
                                        Vector
                                    </NcLink>
                                    <NcLink
                                        className="inline-block font-normal"
                                        href="/search"
                                    >
                                        Frontend
                                    </NcLink>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
            </div>
            {/* ====================== END HEADER ====================== */}

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    {/* TABS FILTER */}
                    <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                        <Nav
                            containerClassName="w-full overflow-x-auto hiddenScrollbar"
                            className="sm:space-x-2"
                        >
                            {TABS.map((item, index) => (
                                <NavItem
                                    isActive={item === tabActive}
                                    key={index}
                                    onClick={() => handleClickTab(item)}
                                >
                                    {item}
                                </NavItem>
                            ))}
                        </Nav>
                        <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                        <div className="flex justify-end">
                            <ArchiveFilterListBox lists={FILTERS} />
                        </div>
                    </div>
                    {loading === 'search'
                        ? displayLoading()
                        : displaySearchContent()}
                    {/* PAGINATION */}
                    {pageSetting.page < pageSetting.totalPage - 1 ? (
                        <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
                            {/* <Pagination
                            setPageSetting={setPageSetting}
                            pageSetting={pageSetting}
                        /> */}
                            <ButtonPrimary onClick={handleMoreSearch}>
                                Show me more
                            </ButtonPrimary>
                        </div>
                    ) : null}
                </main>

                {/* MORE SECTIONS */}
                {/* === SECTION 5 === */}
                <div className="relative py-16">
                    <BackgroundSection />
                    {loading === 'trendingTopic' ? (
                        displayLoading()
                    ) : (
                        <SectionGridCategoryBox
                            categories={trendingTopic.topics}
                        />
                    )}

                    {trendingTopic.page < trendingTopic.totalPage - 1 ? (
                        <div className="text-center mx-auto mt-10 md:mt-16">
                            <ButtonSecondary onClick={handleMoreTopics}>
                                Show me more
                            </ButtonSecondary>
                        </div>
                    ) : null}
                </div>

                {/* === SECTION 5 === */}
                {loading === 'author' ? (
                    displayLoading()
                ) : (
                    <SectionSliderNewAuthors
                        heading="Top elite authors"
                        subHeading="Discover our elite writers"
                        authors={authorsElite}
                    />
                )}

                {/* SUBCRIBES */}
                <SectionSubscribe2 />
            </div>
        </div>
    )
}

export default SearchPageContent
