import React, { useState, useEffect } from 'react'
import './index.css'
import { getData } from './Ncmaz/utils/services'
function Templates() {
    const [componentName, setComponentName] = useState('')

    useEffect(() => {
        getData('/templates?populate=deep&filters[Actived]=true')
            .then((res) => {
                if (res?.data?.data?.length > 0) {
                    setComponentName(res?.data?.data[0]?.attributes?.Title)
                }
            })
            .catch((err) => console.log(err))
    }, [])
    const Component =
        componentName?.length > 0 && require(`./${componentName}`).default
    return <div>{componentName?.length > 0 ? <Component /> : null}</div>
}

export default Templates
