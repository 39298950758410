import React from 'react'
import GallerySlider from './GallerySlider'
import MediaVideo from './MediaVideo'
import PostTypeFeaturedIcon from '../PostTypeFeaturedIcon/PostTypeFeaturedIcon'
import MediaAudio from './MediaAudio'
import Link from '../Link'
import Image from '../Image'


const MyPostFeaturedMedia = ({
    className = 'w-full h-full',
    post,
    isHover = false,
}) => {
    const { mainImage, postType, videoUrl, galleryImgs, audioUrl, slug } =
        post

    const isPostMedia = () => postType === 'video' || postType === 'audio'

    const renderGallerySlider = () => {
        if (!galleryImgs) return null
        return (
            <GallerySlider
                href={slug}
                galleryImgs={galleryImgs}
                className="absolute inset-0 z-10"
                galleryClass="absolute inset-0"
                ratioClass="absolute inset-0"
            />
        )
    }

    const renderContent = () => {
        // GALLERY
        if (postType === 'gallery') {
            return renderGallerySlider()
        }

        // VIDEO
        if (postType === 'video' && !!videoUrl && isHover) {
            return <MediaVideo isHover videoUrl={videoUrl} />
        }

        // AUDIO
        if (postType === 'audio' && !!audioUrl) {
            return <MediaAudio post={post} />
        }

        // ICON
        return isPostMedia() ? (
            <span className="absolute inset-0 flex items-center justify-center ">
                <PostTypeFeaturedIcon
                    className="hover:scale-105 transform cursor-pointer transition-transform"
                    postType={postType}
                />
            </span>
        ) : null
    }

    return (
        <div className={`nc-PostFeaturedMedia relative ${className}`}>
            {postType !== 'gallery' && (
                <Image
                    alt={mainImage?.alternativeText || mainImage?.name}
                    fill
                    className="object-cover"
                    src={process.env.REACT_APP_URL_BE + mainImage?.url}
                    sizes="(max-width: 600px) 480px, 800px"
                />
            )}
            {renderContent()}
            {postType !== 'gallery' && (
                <Link
                    href={slug}
                    className={`block absolute inset-0 ${
                        !postType || postType === 'standard'
                            ? 'bg-black/20 transition-opacity opacity-0 group-hover:opacity-100'
                            : ''
                    }`}
                />
            )}
        </div>
    )
}

export default MyPostFeaturedMedia
