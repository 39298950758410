/* eslint-disable react-hooks/exhaustive-deps */
import SingleHeader from "../../../../app/(singles)/SingleHeader";
import NcImage from "../../../../components/NcImage/NcImage";
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "templates/Ncmaz/utils/services";
import { formatDataNewDetail, formatNewDataArr } from "templates/Ncmaz/utils/function";
import LoadingScreen from "templates/Ncmaz/components/LoadingScreen";

const PageSingleTemplate5 = () => {
  const [newsDetail, setNewsDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [relatedNewsData, setRelatedNewsData] = useState([])
  const [moreFromAuthorData, setMoreFromAuthorData] = useState([])
  const [widgetData, setWidgetData] = useState([])
  const { slug } = useParams()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    getData(`/news-detail/${slug}`)
      .then((res) => {
        setNewsDetail(formatDataNewDetail(res.data.entity))
        setRelatedNewsData(formatNewDataArr(res.data.relatedNews))
        setMoreFromAuthorData(formatNewDataArr(res.data.moreFromAuthor))
        setWidgetData(res.data.widget)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err, 'error')
        setIsLoading(false)
        navigate('/page404')
      })
  }, [slug, navigate])

  useEffect(() => {
    document.title = 'Lotusa CMS | News Detail'
  }, [])

  if (isLoading) return <LoadingScreen />

  return (
    <Layout
      newsDetail={newsDetail}
      relatedNews={relatedNewsData}
      moreFromAuthor={moreFromAuthorData}
      widget={widgetData}
    >
      {/* SINGLE HEADER */}
      <header className="container rounded-xl pt-10 lg:pt-16 ">
        <div className="max-w-screen-md mx-auto">
          <SingleHeader hiddenDesc newsDetail={newsDetail}/>
        </div>

        {/* FEATURED IMAGE */}
        <NcImage
          alt="single"
          containerClassName="my-10 sm:my-12"
          className="object-cover w-full rounded-3xl"
          src={newsDetail?.featuredImage || "https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg"}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
      </header>
    </Layout>
  );
};

export default PageSingleTemplate5;
