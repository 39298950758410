// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationItem_desContent__Pf685 {\n    display: -webkit-box;\n    max-width: 400px;\n    -webkit-line-clamp: 4;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.NavigationItem_titleContent__JlbCU {\n    display: -webkit-box;\n    max-width: 400px;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/templates/Ncmaz/components/Navigation/NavigationItem.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,qBAAqB;IACrB,4BAA4B;IAC5B,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,qBAAqB;IACrB,4BAA4B;IAC5B,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".desContent {\n    display: -webkit-box;\n    max-width: 400px;\n    -webkit-line-clamp: 4;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.titleContent {\n    display: -webkit-box;\n    max-width: 400px;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desContent": "NavigationItem_desContent__Pf685",
	"titleContent": "NavigationItem_titleContent__JlbCU"
};
export default ___CSS_LOADER_EXPORT___;
